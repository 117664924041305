import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export class SMM extends Component {
  render() {
    return (
      <div>
                        <Helmet>
 <meta
      name="description"
      content="Web site created using create-react-app"/>
    <title>SMM | Indofast</title>
  
</Helmet>

<section className="hero-section gradient-overlay">
             <img src="img/banner/smm-banner.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">SMM</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Courses</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

    <section className="service-details-section pt-100 pb-50">
        <Container>
            <Row>

            <Col lg="4" md={4}>
                    <div className="sidebar-right p-4">

                     
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>All Course</h5>
                            </div>
                            <ul className="all-service-list">
                                <li><Link to="/web_design">Web Design Training</Link></li>
                                <li><Link to="/web_analysis">Web Analysis</Link></li>
                                <li><Link to="/content_writing">Content Writing Training</Link></li>
                                <li><Link to="/affiliate_marketing">Affiliate Marketing Training</Link></li>
                                <li><Link to="/pay_per_click">Pay Per Click (PPC) Training</Link></li>
                                <li><Link to="/sem">(SEM) Training</Link></li>
                                <li><Link to="/seo">Search Engine Optimization (SEO) Training</Link></li>
                                <li><Link to="/smm">Social Media Marketing (SMM) Training</Link></li>
                                <li><Link to="/online_reputation_management">Online Reputation Management Training</Link></li>
                                <li><Link to="/wordpress">WordPress Training</Link></li>
                                <li><Link to="/mobile_apps">Mobile Apps Training</Link></li>
                            </ul>
                        </aside>

                     

                    </div>
                </Col>

                <Col lg="8" md={8} >
                    <div className="service-details-wrap">
                        <img src="img/course-details/smm-banner.webp" alt="services" className="img-fluid rounded shadow-sm"/>
                        <div className="services-detail-content mt-4">
                            <h4>Social Media Marketing Training</h4>
                            <p>Social Media Marketing Training is the process of learn how attract an audience, provide value for followers, promote a brand, and set up targeted advertising campaigns on platforms like Facebook and Twitter. If you’re interested in improving your social media skills or building a career in social media these online courses and certifications can help you learn the ropes. Where social media really becomes valuable in a business context is when you can use these social networks to raise brand awareness and also get new customers, leads and sales for a business. Social content marketing is the area where content marketing and social media/business meet.</p>

                            <p>With an increasingly connected consumer and social B2B buying journey, the attention for social content marketing tactics and strategies is growing. However, the term isn’t only about social sharing or optimizing content for social channels. If you want to do these course Social Media Marketing training in Patna? Because we have 13 years of experience in Digital Marketing and we are able to provide Social Media Marketing Training. You can see what’s the benefits of this course for your growing career.</p>

                           


                        </div>
                    </div>
                </Col>

            

                <Col lg="12" md={12}>

<Row className=" mt-5 course-detailis-page-ul">
             
                <Col md={8}>
                <p>With this model, there’s pressure to focus more on the potential income rather than the customers you’re serving. That’s the dark side of affiliate marketing.</p>
<p>And that's not for me. In fact, in early 2009 I tried something similar once. I signed up for an affiliate network and found thousands of potential products I could promote. I chose one and spent about $250 dollars on Adwords hoping someone would click on the link and I'd get paid.</p>
<p>If you dabble in PPC affiliate marketing and it's working for you in a way that you’re instilling trust in your audience, I applaud you and wish you continued success. It's a lot more difficult than it sounds, so props to those of you finding success with it</p>
              
                </Col>
                <Col md={4} >
                    <div className="img-wrap">
                        <img src="img/course-details/smm-img-two.webp" alt="services" className="img-fluid"/>
                    </div>
                </Col>
            </Row>
            <div className="mt-5">
            <hr/>
            </div>

<Row className="justify-content-center">
    <Col lg="10">
    <div className="mt-3">
    <h3>SMM FAQs</h3>
    </div>
            <Accordion className="mt-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header> What topics are typically covered in social media marketing courses?</Accordion.Header>
        <Accordion.Body>
        <p>Social media marketing courses cover a broad range of topics essential for creating and managing effective social media campaigns. These include the fundamentals of social media platforms like Facebook, Instagram, X formerly Twitter, LinkedIn, and TikTok. Learners will explore content creation, audience engagement, and community management strategies. Advanced topics such as social media analytics, paid advertising, influencer marketing, and social media strategy development are also covered. </p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>How can I choose the right social media marketing course for my career goals?</Accordion.Header>
        <Accordion.Body>
         <p>Choosing the right social media marketing course depends on your current skill level and career aspirations. Beginners should look for courses that cover the basics of social media platforms and content creation. Those with some experience might benefit from intermediate courses that explore advanced strategies for audience engagement, analytics, and paid advertising.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What career opportunities can I pursue with a certificate in social media marketing?</Accordion.Header>
        <Accordion.Body>
         <p>A certificate in social media marketing can open up various career opportunities in digital marketing and related fields. Common roles include social media manager, digital marketing specialist, content creator, and social media strategist. These positions involve creating and managing social media content, engaging with audiences, analyzing performance metrics, and developing social media strategies.</p>
        </Accordion.Body>
      </Accordion.Item>


    </Accordion>

                            <div className="action-btns mt-4">
                                <Link to="/enroll_now" className="btn secondary-solid-btn mr-3">Enroll Now</Link>
                            </div>
            </Col>
            </Row>
</Col>
              
            </Row>
        </Container>
    </section>

    <div className="mb-5">
            <hr/>
            </div>
    <section className="address-courses">
      <Container className="container custom-container">
        <Row className=" align-items-center justify-content-center">
          <Col lg="9" md={10} className="mb-30">
            <div className="section-title animation-style2 text-center">

             <h2>Contact Us</h2>
            </div>

          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="4" md={6}>
            <div className="contact-landing">
              <i className="fa fa-map-marker"></i>
         <h4>Reach Us</h4>
         <p>Near Bihar Pharmacy College Gola Road, Bailey Rd, Patna, Bihar 801503
          </p>
        </div>
          </Col>
          <Col lg="4" md={6}>
            <div className="contact-landing">
              <i className="fa fa-phone"></i>
            <h4>Call Us</h4>
            <p><span class="fas fa-phone-alt mr-2"></span> <a href="tel:+91 7488444888"> +91 7488444888</a></p>
         
          </div>
          </Col>
          <Col lg="4" md={6}>
            <div className="contact-landing">
              <i className="fa fa-envelope"></i>
            <h4>Mail Us</h4>
           <p> <a href="mailto:info@indofast.org">info@indofast.org</a></p>
      
          </div>
          </Col>
        
        </Row>
      </Container>
    </section>

      </div>
    )
  }
}

export default SMM
