import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export class Affiliate_Marketing extends Component {
  render() {
    return (
      <div>
                <Helmet>
 <meta
      name="description"
      content="Web site created using create-react-app"/>
    <title>Affiliate Marketing | Indofast</title>
  
</Helmet>

<section className="hero-section gradient-overlay">
             <img src="img/banner/affiliate_marketing_banner.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">Affiliate Marketing</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Courses</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

    <section className="service-details-section ptb-100">
        <Container>
            <Row>
                <Col lg="8" md={8} >
                    <div className="service-details-wrap">
                        <img src="img/course-details/affiliate_marketing_four.webp" alt="services" className="img-fluid rounded shadow-sm"/>
                        <div className="services-detail-content mt-4">
                            <h4>Affiliate Marketing Training</h4>
                            <p>These are your basic pay-per-click affiliate marketing campaigns where you have no presence and no authority in the niche of the product you're promoting. There's no connection between you and the end consumer, and all you're doing is putting an affiliate link in front of someone via Google Adwords, Facebook ads, etc. in the hopes that they'll click on your link, buy the product, and earn a commission.</p>

                            <p>The reason why this type of affiliate marketing is so attractive to many is because no presence or authority is needed! It takes time to build up a reputation and trust with certain groups of people online, and many people are just too scared to commit to working on a blog or website, or just don't have the time. For many, this is their only option.</p>

                            <p>Personally, I don't like this business model because to me, this is not a business model. It's an income generating model, yes, but is it a business model where I can build relationships with the end user? No. With PPC affiliate marketing, you become a behind-the-scenes middle man.</p>


                        </div>
                    </div>
                </Col>

                <Col lg="4" md={4}>
                    <div className="sidebar-right p-4">

                     
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>All Course</h5>
                            </div>
                            <ul className="all-service-list">
                                <li><Link to="/web_design">Web Design Training</Link></li>
                                <li><Link to="/web_analysis">Web Analysis</Link></li>
                                <li><Link to="/content_writing">Content Writing Training</Link></li>
                                <li><Link to="/affiliate_marketing">Affiliate Marketing Training</Link></li>
                                <li><Link to="/pay_per_click">Pay Per Click (PPC) Training</Link></li>
                                <li><Link to="/sem">(SEM) Training</Link></li>
                                <li><Link to="/seo">Search Engine Optimization (SEO) Training</Link></li>
                                <li><Link to="/smm">Social Media Marketing (SMM) Training</Link></li>
                                <li><Link to="/online_reputation_management">Online Reputation Management Training</Link></li>
                                <li><Link to="/wordpress">WordPress Training</Link></li>
                                <li><Link to="/mobile_apps">Mobile Apps Training</Link></li>
                            </ul>
                        </aside>

                     

                    </div>
                </Col>

                <Col lg="12" md={12}>

<Row className=" mt-5 course-detailis-page-ul">
                <Col md={4} >
                    <div className="img-wrap">
                        <img src="img/course-details/affiliate_marketing_img.webp" alt="services" className="img-fluid"/>
                    </div>
                </Col>
                <Col md={8}>
                <p>With this model, there’s pressure to focus more on the potential income rather than the customers you’re serving. That’s the dark side of affiliate marketing.</p>
<p>And that's not for me. In fact, in early 2009 I tried something similar once. I signed up for an affiliate network and found thousands of potential products I could promote. I chose one and spent about $250 dollars on Adwords hoping someone would click on the link and I'd get paid.</p>
<p>If you dabble in PPC affiliate marketing and it's working for you in a way that you’re instilling trust in your audience, I applaud you and wish you continued success. It's a lot more difficult than it sounds, so props to those of you finding success with it</p>
              
                </Col>
            </Row>

<Row className="justify-content-center">
    <Col lg="10">
    <div className="mt-4">
<h3>FAQs</h3>
</div>
            <Accordion className="mt-2">
      <Accordion.Item eventKey="0">
        <Accordion.Header> What is Affiliate Marketing?</Accordion.Header>
        <Accordion.Body>
        <p>Affiliate marketing is another name for promoting another company's products or services on your site. By doing so, you can get a small commission every time a customer purchases their products based on your recommendation.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> How do Affiliate Programs Work?</Accordion.Header>
        <Accordion.Body>
         <p>With the emergence of the Internet, Affiliate marketing has gained prominence too. Since affiliate marketing websites do not promote their products, the algorithm of such promotion may sound confusing. However, it is a highly successful program based on honesty and transparency and is followed by the top multinational companies worldwide.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Types of Affiliate Marketing Strategies</Accordion.Header>
        <Accordion.Body>
         <p>To augment sales, many global businesses work with a diverse number of affiliate marketers. However, the same strategies are not employed by all for maximizing product awareness among consumers.</p>
         <p>In fact, as a potential affiliate marketing partner, you have various affiliate marketing tips and avenues to choose from.</p>
        </Accordion.Body>
      </Accordion.Item>


    </Accordion>

                            <div className="action-btns mt-4">
                                <Link to="/enroll_now" className="btn secondary-solid-btn mr-3">Enroll Now</Link>
                            </div>
            </Col>
            </Row>
</Col>
              
            </Row>
        </Container>
    </section>

      </div>
    )
  }
}

export default Affiliate_Marketing
