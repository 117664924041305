import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export class Pay_Per_Click extends Component {
  render() {
    return (
      <div>
                     <Helmet>
 <meta
      name="description"
      content="Web site created using create-react-app"/>
    <title>Pay Per Click | Indofast</title>
  
</Helmet>

<section className="hero-section gradient-overlay">
             <img src="img/banner/p-p-c-banner.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">Pay Per Click</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Courses</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

    <section className="service-details-section ptb-100">
        <Container>
            <Row>
            <Col lg="4" md={4}>
                    <div className="sidebar-right p-4">

                     
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>All Course</h5>
                            </div>
                            <ul className="all-service-list">
                                <li><Link to="/web_design">Web Design Training</Link></li>
                                <li><Link to="/web_analysis">Web Analysis</Link></li>
                                <li><Link to="/content_writing">Content Writing Training</Link></li>
                                <li><Link to="/affiliate_marketing">Affiliate Marketing Training</Link></li>
                                <li><Link to="/pay_per_click">Pay Per Click (PPC) Training</Link></li>
                                <li><Link to="/sem">(SEM) Training</Link></li>
                                <li><Link to="/seo">Search Engine Optimization (SEO) Training</Link></li>
                                <li><Link to="/smm">Social Media Marketing (SMM) Training</Link></li>
                                <li><Link to="/online_reputation_management">Online Reputation Management Training</Link></li>
                                <li><Link to="/wordpress">WordPress Training</Link></li>
                                <li><Link to="/mobile_apps">Mobile Apps Training</Link></li>
                            </ul>
                        </aside>

                       
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>Need Help?</h5>
                            </div>
                            <p>We are available in 24/7 hours for dedicated support</p>
                            <ul className="primary-list mt-25">
                                <li><span className="ti-location-pin mr-2 color-primary"></span> Near Bihar Pharmacy College Gola Road, Bailey Rd, Patna, Bihar
                                </li>
                                <li><Link to="tel:+91 7488444888"><span className="ti-mobile mr-2 color-primary"></span> +91 7488444888</Link> </li>
                                <li> <Link to="mailto:info@indofast.org"><span className="ti-email mr-2 color-primary"></span> info@indofast.org</Link></li>
                            </ul>
                        </aside>

                      
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>Newsletter</h5>
                            </div>
                            <p>Enter your email address below to subscribe to my newsletter</p>
                            <form action="#" method="post"
                                  className="d-md-block d-lg-block">
                                <input type="text" className="form-control input" id="email-footer" name="email"
                                       placeholder="info@yourdomain.com"/>
                                <button type="submit" className="btn secondary-solid-btn btn-block btn-not-rounded mt-3">Subscribe
                                </button>
                            </form>
                        </aside>

                    </div>
                </Col>
                <Col lg="8" md={8} >
                    <div className="service-details-wrap">
                        <img src="img/course-details/pay-per-click-img.webp" alt="services" className="img-fluid rounded shadow-sm"/>
                        <div className="services-detail-content mt-4">
                            <h4>Pay Per Click Training</h4>
                            <p>Our Pay Per Click training program is suited for individuals who are interested in gaining in-depth knowledge in paid search engine marketing practices. This training course provides a detailed and practical approach to developing, structuring and managing a successful and cost effective Search Engine Marketing advertising campaign. Our PPC training Course mainly focuses on the top 3 search engines – Google, Yahoo and Bing (MSN). This training program will cover all the practical aspects of Google Adwords, targeting best practices for Adwords campaign structure, keyword selection, ad position preferences, and creative optimization.</p>

                            <p>Pay Per Click (PPC) Advertising is the sniper of digital marketing. It puts compelling campaigns in front of targeted audiences based on their expressed interests and demonstrated history. With an understanding of PPC economics and customer psychology, you'll find your prospects in the right place, at the right time.</p>

                            <Row className=" mt-5 course-detailis-page-ul">
                <Col md={6} >
                    <div className="img-wrap">
                        <img src="img/course-details/pay-per-click-img-two.webp" alt="services" className="img-fluid"/>
                    </div>
                </Col>
                <Col md={6}>
                <h4>PPC Training Modules</h4>
                <ul className="list-unstyled tech-feature-list">
                                    
                                        <li className="py-1"><span className="ti-check-box mr-2 color-secondary"></span>
                                        Improving Adwords quality scores
                                        </li>
                                        <li className="py-1"><span className="ti-check-box mr-2 color-secondary"></span>
                                        Keywords research pay per click
                                        </li>
                                        <li className="py-1"><span className="ti-check-box mr-2 color-secondary"></span>Lower your pay per click</li>
                                        <li className="py-1"><span className="ti-check-box mr-2 color-secondary"></span>PPC work flow Managing workflow</li>
                                        <li className="py-1"><span className="ti-check-box mr-2 color-secondary"></span>Why you need a negative keywords tools
                                        </li>

                                        <li className="py-1"><span className="ti-check-box mr-2 color-secondary"></span>PPC & SEO
                                        </li><br/>
                                        
                                        <li className="py-1"><span className="ti-check-box mr-2 color-secondary"></span>The value of PPC automation
                                        </li>
                                       
                                    </ul>
                </Col>
            </Row>

                      
            <div className="mt-4">
<h3>PPC FAQs</h3>
</div>

    <Accordion className="mt-2">
      <Accordion.Item eventKey="0">
        <Accordion.Header>What are the key highlights of the PPC course?</Accordion.Header>
        <Accordion.Body>
        <p>This course is all-inclusive, providing more than 35 hours of high-quality eLearning content. You’ll also be able to test your progress with our online quiz and get a feel for the real world with our downloadable workbooks and exercises. And, the practice tests will help prepare you to take the exam, so you can receive your certification.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> Is the PPC training up to date and relevant?</Accordion.Header>
        <Accordion.Body>
         <p>Indofast strives to provide the latest, best-quality content that’s up to date with current industry standards. Our experienced and certified trainers are subject matter experts who constantly research the latest trends and infuse them into our training programs.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
        Are there any prerequisites to apply for the PPC course? </Accordion.Header>
        <Accordion.Body>
         <p>No. There are no prerequisites required to register for our AdWords PPC training. However, basic understanding and skills in the field of Digital Marketing can benefit your learning experience.</p>
        </Accordion.Body>
      </Accordion.Item>


    </Accordion>

                            <div className="action-btns mt-4">
                                <Link to="/enroll_now" className="btn secondary-solid-btn mr-3">Enroll Now</Link>
                            </div>

                        </div>
                    </div>
                </Col>

              
          
            </Row>
        </Container>
    </section>

      </div>
    )
  }
}

export default Pay_Per_Click
