import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export class Web_Design extends Component {
   
  render() {
    return (
      <div>

<Helmet>
 <meta
      name="description"
      content="Web site created using create-react-app"/>
    <title>Web Design | Indofast</title>
  
</Helmet>

           <section className="hero-section gradient-overlay">
             <img src="img/banner/web-design-banner.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">Web Design</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Courses</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>


    <section className="service-details-section ptb-100">
        <Container>
            <Row>
                <Col lg="8" md={8} >
                    <div className="service-details-wrap">
                        <img src="img/course-details/web-design.webp" alt="services" className="img-fluid rounded shadow-sm"/>
                        <div className="services-detail-content mt-4">
                            <h4>Web Design Training</h4>
                            <p>Web design training is the ideal choice for those students and individuals who really like to create beautiful & innovative websites and have very deep interests to Website Designing. Web designing are increasing day by day and almost all web designing companies hire professional and trained website designers for creating nice and interactive websites for their clients. Web design Training in Patna gives you an opportunity to take a best training for Web Design. We have a higher knowledge and experience in Web designing training if you are looking for a best Web designing Courses located in Patna we are here to improve your skills in Web Designing.</p>

                            

                          
                            <div className="row mt-5 course-detailis-page-ul">
                                <div className="col-md-6">
                                    <div className="img-wrap">
                                        <img src="img/course-details/images.webp" alt="services" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <p>The benefit of getting trained with the best web designing training institute is that you get a lot of exposure and skills that you might lack if you choose a regular training institute.</p>
                                    <ul className="list-unstyled tech-feature-list">
                                    
                                        <li className="py-1"><span className="ti-check-box mr-2 color-secondary"></span>
                                            Get Certified at the Best Web Designing Training Institute.
                                        </li>
                                        <li className="py-1"><span className="ti-check-box mr-2 color-secondary"></span>
                                            Get trained by industry experts.
                                        </li>
                                        <li className="py-1"><span className="ti-check-box mr-2 color-secondary"></span>10+ Real-time projects</li>
                                        <li className="py-1"><span className="ti-check-box mr-2 color-secondary"></span>100% career-oriented training</li>
                                        <li className="py-1"><span className="ti-check-box mr-2 color-secondary"></span>Internship and Placement Support
                                        </li>
                                       
                                    </ul>
                                </div>
                            </div>

                      
<div className="mt-4">
<h3>FAQs</h3>
</div>
                            <Accordion className="mt-2">
      <Accordion.Item eventKey="0">
        <Accordion.Header> What is web design?</Accordion.Header>
        <Accordion.Body>
        <p>Web design involves creating the visual layout and structure of websites, encompassing elements like layout, color scheme, typography, and user experience.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> What skills are required for web design?</Accordion.Header>
        <Accordion.Body>
         <p>Web designers typically need proficiency in graphic design software, understanding of programming languages like HTML, CSS, and JavaScript, as well as knowledge of UX/UI principles and responsive design.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What are the current trends in web design?</Accordion.Header>
        <Accordion.Body>
         <p>Current trends include minimalist design, dark mode, micro-interactions, immersive storytelling, and a focus on accessibility and inclusivity.</p>
        </Accordion.Body>
      </Accordion.Item>


    </Accordion>

                            <div className="action-btns mt-4">
                                <Link to="/enroll_now" className="btn secondary-solid-btn mr-3">Enroll Now</Link>
                            </div>

                        </div>
                    </div>
                </Col>
                <Col lg="4" md={4}>
                    <div className="sidebar-right p-4">

                     
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>All Course</h5>
                            </div>
                            <ul className="all-service-list">
                                <li><Link to="/web_design">Web Design Training</Link></li>
                                <li><Link to="/web_analysis">Web Analysis</Link></li>
                                <li><Link to="/content_writing">Content Writing Training</Link></li>
                                <li><Link to="/affiliate_marketing">Affiliate Marketing Training</Link></li>
                                <li><Link to="/pay_per_click">Pay Per Click (PPC) Training</Link></li>
                                <li><Link to="/sem">(SEM) Training</Link></li>
                                <li><Link to="/seo">Search Engine Optimization (SEO) Training</Link></li>
                                <li><Link to="/smm">Social Media Marketing (SMM) Training</Link></li>
                                <li><Link to="/online_reputation_management">Online Reputation Management Training</Link></li>
                                <li><Link to="/wordpress">WordPress Training</Link></li>
                                <li><Link to="/mobile_apps">Mobile Apps Training</Link></li>
                            </ul>
                        </aside>

                       
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>Need Help?</h5>
                            </div>
                            <p>We are available in 24/7 hours for dedicated support</p>
                            <ul className="primary-list mt-25">
                                <li><span className="ti-location-pin mr-2 color-primary"></span> Near Bihar Pharmacy College Gola Road, Bailey Rd, Patna, Bihar
                                </li>
                                <li><Link to="tel:+91 7488444888"><span className="ti-mobile mr-2 color-primary"></span> +91 7488444888</Link> </li>
                                <li> <Link to="mailto:info@indofast.org"><span className="ti-email mr-2 color-primary"></span> info@indofast.org</Link></li>
                            </ul>
                        </aside>

                      
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>Newsletter</h5>
                            </div>
                            <p>Enter your email address below to subscribe to my newsletter</p>
                            <form action="#" method="post"
                                  className="d-md-block d-lg-block">
                                <input type="text" className="form-control input" id="email-footer" name="email"
                                       placeholder="info@yourdomain.com"/>
                                <button type="submit" className="btn secondary-solid-btn btn-block btn-not-rounded mt-3">Subscribe
                                </button>
                            </form>
                        </aside>

                    </div>
                </Col>
            </Row>
        </Container>
    </section>

      </div>
    )
  }
}

export default Web_Design
