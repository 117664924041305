import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom'


export class Gallery_Categories extends Component {
    componentDidMount(){
        document.title = "Gallery Categories | Indofast"
    }
  render() {
    return (
      <div>
                       <section className="hero-section gradient-overlay">
             <img src="img/banner/gallery-c.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">Gallery Categories</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Gallery Categories</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

    <section class="our-blog-section ">
        <Container>
   
            <Row className="justify-content-center">
                <Col lg="4" md={6}>
                    <div class="single-blog-card card border-0 shadow-sm">
                        <div class="blog-img position-relative">
                            <img src="img/gallery/a1.webp" class="card-img-top" alt="blog"/>
                            <div class="meta-date">
                                <strong>03</strong>
                                <small>Oct</small>
                            </div>
                        </div>
                        <div class="card-body">
                       
                            <h3 class="h5 mb-2 card-title"><Link to="/gallery"> Patna 03/10/2021 </Link></h3>
                         
                            <Link to="/gallery" class="detail-link">View more <span class="ti-arrow-right"></span></Link>
                        </div>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div class="single-blog-card card border-0 shadow-sm">
                        <div class="blog-img position-relative">
                            <img src="img/gallery/a21.webp" class="card-img-top" alt="blog"/>
                            <div class="meta-date">
                                <strong>09</strong>
                                <small>Aug</small>
                            </div>
                        </div>
                        <div class="card-body">
                       
                            <h3 class="h5 mb-2 card-title"><Link to="/gallery"> Patna 09/08/2021 </Link></h3>
                         
                            <Link to="/gallery" class="detail-link">View more <span class="ti-arrow-right"></span></Link>
                        </div>
                    </div>
                </Col>

            </Row>
        </Container>
    </section>

      </div>
    )
  }
}

export default Gallery_Categories
