import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export class Web_Analysis extends Component {
  render() {
    return (
      <div>
<Helmet>
 <meta
      name="description"
      content="Web site created using create-react-app"/>
    <title>Web Analysis | Indofast</title>
  
</Helmet>

<section className="hero-section gradient-overlay">
             <img src="img/banner/web-analysis-banner.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">Web Analysis</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Courses</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>


    <section className="service-details-section ptb-100">
        <Container>
            <Row>
            <Col lg="4" md={4}>
                    <div className="sidebar-right p-4">

                     
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>All Course</h5>
                            </div>
                            <ul className="all-service-list">
                                <li><Link to="/web_design">Web Design Training</Link></li>
                                <li><Link to="/web_analysis">Web Analysis</Link></li>
                                <li><Link to="/content_writing">Content Writing Training</Link></li>
                                <li><Link to="/affiliate_marketing">Affiliate Marketing Training</Link></li>
                                <li><Link to="/pay_per_click">Pay Per Click (PPC) Training</Link></li>
                                <li><Link to="/sem">(SEM) Training</Link></li>
                                <li><Link to="/seo">Search Engine Optimization (SEO) Training</Link></li>
                                <li><Link to="/smm">Social Media Marketing (SMM) Training</Link></li>
                                <li><Link to="/online_reputation_management">Online Reputation Management Training</Link></li>
                                <li><Link to="/wordpress">WordPress Training</Link></li>
                                <li><Link to="/mobile_apps">Mobile Apps Training</Link></li>
                            </ul>
                        </aside>

                       
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>Need Help?</h5>
                            </div>
                            <p>We are available in 24/7 hours for dedicated support</p>
                            <ul className="primary-list mt-25">
                                <li><span className="ti-location-pin mr-2 color-primary"></span> Near Bihar Pharmacy College Gola Road, Bailey Rd, Patna, Bihar
                                </li>
                                <li><Link to="tel:+91 7488444888"><span className="ti-mobile mr-2 color-primary"></span> +91 7488444888</Link> </li>
                                <li> <Link to="mailto:info@indofast.org"><span className="ti-email mr-2 color-primary"></span> info@indofast.org</Link></li>
                            </ul>
                        </aside>

                      
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>Newsletter</h5>
                            </div>
                            <p>Enter your email address below to subscribe to my newsletter</p>
                            <form action="#" method="post"
                                  className="d-md-block d-lg-block">
                                <input type="text" className="form-control input" id="email-footer" name="email"
                                       placeholder="info@yourdomain.com"/>
                                <button type="submit" className="btn secondary-solid-btn btn-block btn-not-rounded mt-3">Subscribe
                                </button>
                            </form>
                        </aside>

                    </div>
                </Col>
                <Col lg="8" md={8} >
                    <div className="service-details-wrap">
                        <img src="img/course-details/web_analysis.webp" alt="services" className="img-fluid rounded shadow-sm"/>
                        <div className="services-detail-content mt-4">
                            <h4>Web Analysis Training</h4>
                            <p>Web analytics involves collecting, measuring, and analyzing website data. Web Analytics tools can provide lots of useful information about the origin of website traffic, how website users navigate and interact throughout a website, what content and web pages they're most engaged with, and how they exit the site. Marketers can then use this data to optimize the performance of their channels and websites by taking data-led decisions. These are actions we take to improve performance based on our understanding of the data we’ve recorded; in other words, the data is leading the decision. While it’s best practice to follow data-led decisions, we must always remind ourselves to make sure we test and verify the data.</p>

                            

                          
                            <div className="row mt-5 course-detailis-page-ul">
                                <div className="col-md-6">
                                    <div className="img-wrap">
                                        <img src="img/course-details/web_analysis-two.webp" alt="services" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <p>Web analytics tools provide information about the origin of website traffic, how users navigate and interact throughout a website, what content and webpages they’re most engaged with, and if they take valuable actions on the site, known as conversions, these include purchases or contact requests. Using this data, marketers can optimize channel and website performance with data-led decisions.</p>
                               
                                </div>
                            </div>

                            <div className="mt-4">
<h3>FAQs</h3>
</div>

                            <Accordion className="mt-2">
      <Accordion.Item eventKey="0">
        <Accordion.Header> How does web analytics help improve digital marketing efforts?</Accordion.Header>
        <Accordion.Body>
        <p>Web analytics provides valuable insights into user behavior, such as website traffic, demographics, and conversions. This data helps marketers understand their audience better, optimize marketing campaigns, improve website performance, and make informed decisions to drive better results.
</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What are some key metrics tracked in web analytics?</Accordion.Header>
        <Accordion.Body>
         <p>Key metrics tracked in web analytics include website traffic (page views, unique visitors), user engagement (bounce rate, time on site), conversions (click-through rates, conversion rates), and customer acquisition (cost per acquisition, return on investment). These metrics help gauge the effectiveness of marketing efforts and identify areas for improvement.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Which tools are commonly used for web analytics?</Accordion.Header>
        <Accordion.Body>
         <p>Popular web analytics tools include Google Analytics, Adobe Analytics, Mixpanel, and Piwik. These tools offer various features to track and analyze website data, generate reports, and gain actionable insights into digital marketing performance.</p>
        </Accordion.Body>
      </Accordion.Item>


    </Accordion>

                            <div className="action-btns mt-4">
                                <Link to="/enroll_now" className="btn secondary-solid-btn mr-3">Enroll Now</Link>
                            </div>

                        </div>
                    </div>
                </Col>
          
            </Row>
        </Container>
    </section>



      </div>
    )
  }
}

export default Web_Analysis
