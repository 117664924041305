import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export class SEM extends Component {
  render() {
    return (
      <div>
                                     <Helmet>
 <meta
      name="description"
      content="Web site created using create-react-app"/>
    <title>SEM | Indofast</title>
  
</Helmet>

<section className="hero-section gradient-overlay">
             <img src="img/banner/sem-banner.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">SEM</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Courses</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

    <section className="service-details-section pt-100 pb-80">
        <Container>
            <Row>
            <Col lg="4" md={4}>
                    <div className="sidebar-right p-4">

                     
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>All Course</h5>
                            </div>
                            <ul className="all-service-list">
                                <li><Link to="/web_design">Web Design Training</Link></li>
                                <li><Link to="/web_analysis">Web Analysis</Link></li>
                                <li><Link to="/content_writing">Content Writing Training</Link></li>
                                <li><Link to="/affiliate_marketing">Affiliate Marketing Training</Link></li>
                                <li><Link to="/pay_per_click">Pay Per Click (PPC) Training</Link></li>
                                <li><Link to="/sem">(SEM) Training</Link></li>
                                <li><Link to="/seo">Search Engine Optimization (SEO) Training</Link></li>
                                <li><Link to="/smm">Social Media Marketing (SMM) Training</Link></li>
                                <li><Link to="/online_reputation_management">Online Reputation Management Training</Link></li>
                                <li><Link to="/wordpress">WordPress Training</Link></li>
                                <li><Link to="/mobile_apps">Mobile Apps Training</Link></li>
                            </ul>
                        </aside>

                     

                    </div>
                </Col>
                <Col lg="8" md={8} >
                    <div className="service-details-wrap">
                        <img src="img/course-details/SEM-img.webp" alt="services" className="img-fluid rounded shadow-sm"/>
                        <div className="services-detail-content mt-4">
                            <h4>Search Engine Marketing Training</h4>
                            <p>Our SEO training is flexible to meet the demands of fresher’s and working professionals, SEO training can be started any time the date of enrolment. SEO courses and advance SEO training should have passion and interest in learning online marketing, online business and all its concepts. We offer a number of Advance & Corporate SEO training program in Patna this training will help you take advantage of and develop your own personal knowledge base in Search Engine Optimization. Our SEO training program are designed to bring you up to speed on how to Search Engine Operating.</p>

                            <p>SEO is relatively a new industry that started at the onset of e-commerce worldwide. It was not hard of even few years ago. But the situation has changed completely now and every business, despite their size, need support of strategic SEO planning to improve their visibility across web platforms.</p>

                            <p>SEO training courses comprises the internet marketing aspect and content writing, which would need flair for writing and strong knowledge of English language. The SEO training course focus on offering hands-on, industry exposure, that will help candidates find appropriate job placements after course completion.</p>


                        </div>
                    </div>
                </Col>

                <Col lg="12" md={12}>

<Row className=" mt-5 course-detailis-page-ul">
            
                <Col md={8}>
                <p>Salaries for SEO and content writers have no boundaries for the right candidate. Job opportunities are available in SEO firms, as freelancers and independent consultants.</p>
<p>The scenario currently is that anyone can become a content or SEO expert. The result is mediocre and poorly written content and mislaid SEO strategy that fail to intrigue readers. The courses are designed to provide with right direction to students to write appealing, engaging and effective content that would improve ROI.</p>
<p>SEO Training courses cover both niche and wider aspects of the industry in the line of training. They blend theory with practice so that students learn the many ways to write a piece of content that would stand out in the crowd, making reader fall in love with it.</p>
              
                </Col>

                <Col md={4} >
                    <div className="img-wrap">
                        <img src="img/course-details/sem-two.webp" alt="services" className="img-fluid"/>
                    </div>
                </Col>

            </Row>

            <Col lg="12" md={12}>
<p className='mt-4'>There are jobs getting created in the industry in thousands and graduates can take advantage of this progressive industry if they are property trained as per industry standards. Indian firms are catering to both domestic and international clientele and that demands professionals, learned on current market requirements and changing trends.</p>
<p>The opportunity of becoming a world leader because it lacked in proper training facilities and courses to help young aspirants become SEO experts. The situation however is changing because of some forward thinking SEO course providers—SEO Training Program is one of them. The institute assumes a leadership position in offering tailored professional SEO and content writing courses to graduates.</p>
            </Col>   
            <div className="mt-5">
            <hr/>
            </div>
<Row className="justify-content-center">
    <Col lg="10">
 
    <div class="mt-3"><h3>SEM FAQs</h3></div>
            <Accordion className="mt-2">
      <Accordion.Item eventKey="0">
        <Accordion.Header>How does SEM work?</Accordion.Header>
        <Accordion.Body>
        <p>This module gives you a brief overview of paid marketing and also talks about understanding the customers in the digital ecosystem.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Search Engine Result Page Structure</Accordion.Header>
        <Accordion.Body>
         <p>In this module, you will learn about the result page setup in a step-by-step manner with a detailed explanation about the same.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Why do we need SEM?</Accordion.Header>
        <Accordion.Body>
         <p>While ranking websites across various search engines, this module will help you understand the need for SEM in today's world.</p>
        </Accordion.Body>
      </Accordion.Item>


    </Accordion>

                            <div className="action-btns mt-4">
                                <Link to="/enroll_now" className="btn secondary-solid-btn mr-3">Enroll Now</Link>
                            </div>
            </Col>
            </Row>
</Col>


          
            </Row>
        </Container>
    </section>
    <div className="mb-5">
            <hr/>
            </div>
    <section className="address-courses">
      <Container className="container custom-container">
        <Row className=" align-items-center justify-content-center">
          <Col lg="9" md={10} className="mb-30">
            <div className="section-title animation-style2 text-center">

             <h2>Contact Us</h2>
            </div>

          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="4" md={6}>
            <div className="contact-landing">
              <i className="fa fa-map-marker"></i>
         <h4>Reach Us</h4>
         <p>Near Bihar Pharmacy College Gola Road, Bailey Rd, Patna, Bihar 801503
          </p>
        </div>
          </Col>
          <Col lg="4" md={6}>
            <div className="contact-landing">
              <i className="fa fa-phone"></i>
            <h4>Call Us</h4>
            <p><span class="fas fa-phone-alt mr-2"></span> <a href="tel:+91 7488444888"> +91 7488444888</a></p>
         
          </div>
          </Col>
          <Col lg="4" md={6}>
            <div className="contact-landing">
              <i className="fa fa-envelope"></i>
            <h4>Mail Us</h4>
           <p> <a href="mailto:info@indofast.org">info@indofast.org</a></p>
      
          </div>
          </Col>
        
        </Row>
      </Container>
    </section>

      </div>
    )
  }
}

export default SEM
