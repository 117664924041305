import './App.css';
import Header from './components/Header';
import Header_To from './components/Header_To';
import Home from './components/Home';
import Enroll_Now from './components/Enroll_Now';
import Contact from './components/Contact';
import Blog from './components/Blog';
import Blog_Details from './components/Blog_Details';
import Footer from './components/Footer';
import Faq from './components/Faq';
import Gallery from './components/Gallery';
import Video from './components/Video';
import Gallery_Categories from './components/Gallery_Categories';
import Courses from './components/Courses';
import Web_Design from './components/Web_Design';
import Web_Analysis from './components/Web_Analysis';
import Content_Writing from './components/Content_Writing';
import Affiliate_Marketing from './components/Affiliate_Marketing';
import Pay_Per_Click from './components/Pay_Per_Click';
import SEM from './components/SEM';
import SEO from './components/SEO';
import SMM from './components/SMM';
import Online_Reputation_Management from './components/Online_Reputation_Management';
import Director_Message from './components/Director_Message';
import About from './components/About';
import { BrowserRouter as Router ,Routes ,Route } from 'react-router-dom';
import Wordpress from './components/Wordpress';
import Mobile_Apps from './components/Mobile_Apps';


function App() {
  return (
 <>
 <Router>
 <Header />
<Header_To />
<Routes>
<Route exact path="/" element={<Home />}/>
<Route exact path="/about" element={<About />}/>
<Route exact path="/director_message" element={<Director_Message />}/>
<Route exact path="/courses" element={<Courses />}/>
<Route exact path="/web_design" element={<Web_Design />}/>
<Route exact path="/web_analysis" element={<Web_Analysis />}/>
<Route exact path="/content_writing" element={<Content_Writing />}/>
<Route exact path="/affiliate_marketing" element={<Affiliate_Marketing />}/>
<Route exact path="/pay_per_click" element={<Pay_Per_Click />}/>
<Route exact path="/sem" element={<SEM />}/>
<Route exact path="/seo" element={<SEO />}/>
<Route exact path="/smm" element={<SMM />}/>
<Route exact path="/online_reputation_management" element={<Online_Reputation_Management />}/>
<Route exact path="/wordpress" element={<Wordpress />}/>
<Route exact path="/mobile_apps" element={<Mobile_Apps />}/>
<Route exact path="/gallery_categories" element={<Gallery_Categories />}/>
<Route exact path="/gallery" element={<Gallery />}/>
<Route exact path="/video" element={<Video />}/>
<Route exact path="/faq" element={<Faq />}/>
<Route exact path="/blog" element={<Blog />}/>
<Route exact path="/blog_details" element={<Blog_Details />}/>
<Route exact path="/contact" element={<Contact />}/>
<Route exact path="/enroll_now" element={<Enroll_Now />}/>
</Routes>
<Footer />
</Router>

 </>
  );
}

export default App;
