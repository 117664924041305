import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

export class Courses extends Component {
    componentDidMount(){
        document.title = "Courses | Indofast"
    }
  render() {
    return (
      <div>
           <section className="hero-section gradient-overlay">
             <img src="img/banner/Courses.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">Our Courses</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Our Courses</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>


    <section className="services-section ptb-70 gray-light-bg">
        <Container>
     
            <Row className="justify-content-center">
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                      <img src="img/course/website-design-final.jpg" alt=""/>
                        <h5>Web Design Training</h5>
                        <Link to="/web_design" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/web-analysis.webp" alt=""/>
                        <h5>Web Analysis </h5>
                        <Link to="/web_analysis" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/content.webp" alt=""/>
                        <h5>Content Writing Training</h5>
                        <Link to="/content_writing" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/affiliate-marketing.webp" alt=""/>
                        <h5>Affiliate Marketing Training</h5>
                        <Link to="/affiliate_marketing" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/ppc.webp" alt=""/>
                        <h5>Pay Per Click (PPC) Training</h5>
                        <Link to="/pay_per_click" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/sem.webp" alt=""/>
                        <h5> (SEM) Training</h5>
                        <Link to="/sem" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/seo.webp" alt=""/>
                        <h5>Search Engine Optimization (SEO) Training</h5>
                        <Link to="/seo" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/smm.webp" alt=""/>
                        <h5> Social Media Marketing (SMM) Training</h5>
                        <Link to="/smm" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/reputation-management.webp" alt=""/>
                        <h5>Online Reputation Management Training</h5>
                        <Link to="/online_reputation_management" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/wordpress.webp" alt=""/>
                        <h5>WordPress Training</h5>
                        <Link to="/wordpress" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/mobile-app.webp" alt=""/>
                        <h5> Mobile Apps Training</h5>
                        <Link to="/mobile_apps" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                {/* <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/other.webp" alt=""/>
                        <h5>Others</h5>
                        <Link to="/course_details" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col> */}
            </Row>
        </Container>
    </section>

      </div>
    )
  }
}

export default Courses
