import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom'
import ScrollToTop from "react-scroll-to-top";

export class Footer extends Component {
  render() {
    return (
      <div>
     
    <footer className="footer-section" >

    <div className="footer-top gradient-bg">
        <Container>
            <Row>
                <Col lg="9" md={12} sm={12} >
                    <Row className="footer-top-wrap">
                        <Col md={4} sm={6} >
                            <div className="footer-nav-wrap text-white">
                                <img src="img/logowhite.png" alt="logo" className="mb-2"/>
                                <p>Digital Marketing is the process of promoting your products or brands via one or more forms of electronic media.</p>
                                <div className="social-nav mt-4">
                                    <ul className="list-unstyled social-list mb-0">
                                        <li className="list-inline-item tooltip-hover">
                                            <Link target="_blank" to="https://www.facebook.com/indofast.in/" className="rounded"><span className="ti-facebook"></span></Link>
                                            <div className="tooltip-item">Facebook</div>
                                        </li>
                                         <li className="list-inline-item tooltip-hover"><Link target="_blank" to="https://www.instagram.com/indofast.in/" className="rounded"><span
                                                className="ti-instagram"></span></Link>
                                            <div className="tooltip-item">Instagram</div>
                                        </li>
                                        <li className="list-inline-item tooltip-hover"><Link target="_blank" to="https://x.com/iindofast" className="rounded"><span
                                                className="ti-twitter"></span></Link>
                                            <div className="tooltip-item">Twitter</div>
                                        </li>
                                        <li className="list-inline-item tooltip-hover"><Link target="_blank" to="https://www.linkedin.com/in/indofast/" className="rounded"><span
                                                className="ti-linkedin"></span></Link>
                                            <div className="tooltip-item">Linkedin</div>
                                        </li>
                                        <li className="list-inline-item tooltip-hover"><Link target="_blank" to="https://www.youtube.com/@indofast" className="rounded"><span
                                                className="fa fa-youtube-play"></span></Link>
                                            <div className="tooltip-item">Youtube</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} sm={6}>
                            <div className="footer-nav-wrap text-white">
                                <h4 className="text-white"> Quick Links </h4>
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                    <Link className="nav-link" to="/about">About</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/bolg">Blog</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link target="_blank" className="nav-link" to="https://indofast.org/patna/Collage/index.php">College login</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/faq">FAQ</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/contact"> Contact Us</Link>
                                    </li>
                                   
                                </ul>
                            </div>
                        </Col>
                        <Col md={4} sm={6} >
                            <div className="footer-nav-wrap text-white">
                                <h4 className="text-white">Top Courses</h4>
                                <ul className="nav flex-column">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/content_writing">Content Writing Training</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/web_design">Web Design Training</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/affiliate_marketing">Affiliate Marketing Training</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/mobile_apps">Mobile Apps Training</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/wordpress">WordPress Training</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/sem">(SEM) Training</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg="3" md={6} sm={12} >
                    <Row className=" footer-top-wrap mt-md-4 mt-sm-0 mt-lg-0">
                        <Col sm={12} >
                            <div className="footer-nav-wrap text-white">
                                <h4 className="text-white"> Get in touch</h4>
                                <ul className="get-in-touch-list">
                                    <li className="d-flex align-items-center py-2"><span
                                            className="fas fa-map-marker-alt mr-2"></span> Near Bihar Pharmacy College
                                            Gola Road, Bailey Rd, Patna,
                                            Bihar 801503
                                    </li>
                                    <li className="d-flex align-items-center py-2"><span
                                            className="fas fa-envelope mr-2"></span> <Link to="mailto:info@indofast.org">info@indofast.org</Link>
                                    </li>
                                    <li className="d-flex align-items-center py-2"><span className="fas fa-phone-alt mr-2"></span><Link to="tel:+91 7488444888">+91 7488444888</Link></li>
                                </ul>
                              
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </div>
   
    <div className="footer-bottom gray-light-bg py-2">
        <Container>
            <Row className="align-items-center justify-content-between">
                <Col lg="6" md={5} >
                    <p className="copyright-text pb-0 mb-0">© All Rights Reserved 2024| Indofast Digital Media</p>
                </Col>
                <Col lg="6" md={7} className="d-none d-md-block d-lg-block">
                    <ul className="list-inline policy-nav text-right social-list">
                        <li className="list-inline-item"><a href="privacy_policy.html">Privacy Policy</a></li>
                        <li className="list-inline-item"><a href="#">Terms & Conditions</a></li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </div>
   
</footer>

<ScrollToTop smooth />

<Link target="_blank" to="https://wa.me/917488444888" className="btn-whatsapp-pulse">
    <i class="fab fa-whatsapp"></i>
</Link>
<Link target="_blank" to="tel:+91 7488444888" className="btn-phone-icon">
  <i class="fa fa-phone"></i>
</Link>

{/* <div className="prt_floting_customsett">
            <Link target="_blank" to="tel:+91 7488444888" className="tmtheme_fbar_icons"><i className="fa fa-phone"></i><span>Call</span></Link>
            <Link target="_blank" to="https://wa.me/917488444888" className="tmtheme_fbar_icons"><i className="fa fa-whatsapp"></i><span>Whatsapp</span></Link>
            <Link target="_blank" to="https://www.facebook.com/indofast.in/" className="tmtheme_fbar_icons"><i className="ti-facebook"></i><span>Facebook</span></Link>
            <Link target="_blank" to="https://www.instagram.com/indofast.in/" className="tmtheme_fbar_icons"><i className="ti-instagram"></i><span>Instagram</span></Link>
            <Link target="_blank" to="https://x.com/iindofast" className="tmtheme_fbar_icons"><i className="ti-twitter"></i><span>Twitter</span></Link>
            <Link target="_blank" to="https://www.linkedin.com/in/indofast/" className="tmtheme_fbar_icons"><i className="ti-linkedin"></i><span>linkedin</span></Link>
            <Link target="_blank" to="https://www.youtube.com/@indofast" className="tmtheme_fbar_icons"><i className="fa fa-youtube-play"></i><span>Youtube</span></Link>
        </div> */}

      </div>
    )
  }
}

export default Footer
