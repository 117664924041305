import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom'

export class Blog extends Component {
    componentDidMount(){
        document.title = "Blog | Indofast"
    }
  render() {
    return (
      <div>
                    <section className="hero-section gradient-overlay">
             <img src="img/banner/blog.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">Our Blog</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Blog</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

    <section class="our-blog-section ">
        <Container>
   
            <Row>
                <Col lg="4" md={6}>
                    <div class="single-blog-card card border-0 shadow-sm">
                        <div class="blog-img position-relative">
                            <img src="img/blog/1.webp" class="card-img-top" alt="blog"/>
                            <div class="meta-date">
                                <strong>15</strong>
                                <small>Jan</small>
                            </div>
                        </div>
                        <div class="card-body">
                       
                            <h3 class="h5 mb-2 card-title"><Link to="/blog_details"> Why Learn Digital Marketing? </Link></h3>
                          
                            <Link to="/blog_details" class="detail-link">Read more <span class="ti-arrow-right"></span></Link>
                        </div>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div class="single-blog-card card border-0 shadow-sm">
                        <div class="blog-img position-relative">
                            <img src="img/blog/new-blog-1.webp" class="card-img-top" alt="blog"/>
                            <div class="meta-date">
                                <strong>15</strong>
                                <small>Jan</small>
                            </div>
                        </div>
                        <div class="card-body">
                      
                            <h3 class="h5 mb-2 card-title"><Link to="/blog_details">The Right seo strategies </Link></h3>
                         
                            <Link to="/blog_details" class="detail-link">Read more <span class="ti-arrow-right"></span></Link>
                        </div>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div class="single-blog-card card border-0 shadow-sm">
                        <div class="blog-img position-relative">
                            <img src="img/blog/new-blog-2.webp" class="card-img-top" alt="blog"/>
                            <div class="meta-date">
                                <strong>15</strong>
                                <small>Jan</small>
                            </div>
                        </div>
                        <div class="card-body">
                       
                            <h3 class="h5 mb-2 card-title"><Link to="/blog_details">Social media marketing</Link></h3>
                          
                            <Link to="/blog_details" class="detail-link">Read more <span class="ti-arrow-right"></span></Link>
                        </div>
                    </div>
                </Col>

                <Col lg="4" md={6}>
                    <div class="single-blog-card card border-0 shadow-sm">
                        <div class="blog-img position-relative">
                            <img src="img/blog/new-blog-3.webp" class="card-img-top" alt="blog"/>
                            <div class="meta-date">
                                <strong>15</strong>
                                <small>Jan</small>
                            </div>
                        </div>
                        <div class="card-body">
                       
                            <h3 class="h5 mb-2 card-title"><Link to="/blog_details">Content Management</Link></h3>
                            
                            <Link to="/blog_details" class="detail-link">Read more <span class="ti-arrow-right"></span></Link>
                        </div>
                    </div>
                </Col>

                <Col lg="4" md={6}>
                    <div class="single-blog-card card border-0 shadow-sm">
                        <div class="blog-img position-relative">
                            <img src="img/blog/new-blog-4.webp" class="card-img-top" alt="blog"/>
                            <div class="meta-date">
                                <strong>15</strong>
                                <small>Jan</small>
                            </div>
                        </div>
                        <div class="card-body">
                       
                            <h3 class="h5 mb-2 card-title"><Link to="/blog_details">Who Should Learn Digital Marketing</Link></h3>
                       
                            <Link to="/blog_details" class="detail-link">Read more <span class="ti-arrow-right"></span></Link>
                        </div>
                    </div>
                </Col>

                <Col lg="4" md={6}>
                    <div class="single-blog-card card border-0 shadow-sm">
                        <div class="blog-img position-relative">
                            <img src="img/blog/new-blog-5.webp" class="card-img-top" alt="blog"/>
                            <div class="meta-date">
                                <strong>15</strong>
                                <small>Jan</small>
                            </div>
                        </div>
                        <div class="card-body">
                       
                            <h3 class="h5 mb-2 card-title"><Link to="/blog_details"> Best Traffic Generation Techniques</Link></h3>
                         
                            <Link to="/blog_details" class="detail-link">Read more <span class="ti-arrow-right"></span></Link>
                        </div>
                    </div>
                </Col>

            </Row>
        </Container>
    </section>

      </div>
    )
  }
}

export default Blog
