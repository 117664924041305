import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export class Mobile_Apps extends Component {
  render() {
    return (
      <div>
             <Helmet>
 <meta
      name="description"
      content="Web site created using create-react-app"/>
    <title>Mobile Apps | Indofast</title>
  
</Helmet>

<section className="hero-section gradient-overlay">
             <img src="img/banner/mobile-apps-banner.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">Mobile Apps</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Courses</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

    <section className="service-details-section pt-100 pb-50">
        <Container>
            <Row>
                <Col lg="8" md={8} >
                    <div className="service-details-wrap">
                        <img src="img/course-details/mobile-apps.webp" alt="services" className="img-fluid rounded shadow-sm"/>
                        <div className="services-detail-content mt-4">
                            <h4>Mobile Apps Courses Training</h4>
                            <p>Mobile app developer career stable choice for your career. Most employers seek Mobile app development with the least bachelor's degree but within app development training certificates in software engineering. Mobile App Developer using the objective programming languages are C, C++, C# or Java. Mobile Application developer will develop for Android or iOS than beginner and experienced person learns the advanced programming language and software development around for that platform.</p>

                            <p>Mobile Application Developer Skills and Responsibilities: Mobile app developer fluent with Object-Oriented Programming languages such as Java, C, and C++. Mobile application developer creating for Application Programming Interface (API) in mobile capabilities, and they are skilled in coding, debug, design and developing and document changing.</p>

                        </div>
                    </div>
                </Col>

                <Col lg="4" md={4}>
                    <div className="sidebar-right p-4">

                     
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>All Course</h5>
                            </div>
                            <ul className="all-service-list">
                                <li><Link to="/web_design">Web Design Training</Link></li>
                                <li><Link to="/web_analysis">Web Analysis</Link></li>
                                <li><Link to="/content_writing">Content Writing Training</Link></li>
                                <li><Link to="/affiliate_marketing">Affiliate Marketing Training</Link></li>
                                <li><Link to="/pay_per_click">Pay Per Click (PPC) Training</Link></li>
                                <li><Link to="/sem">(SEM) Training</Link></li>
                                <li><Link to="/seo">Search Engine Optimization (SEO) Training</Link></li>
                                <li><Link to="/smm">Social Media Marketing (SMM) Training</Link></li>
                                <li><Link to="/online_reputation_management">Online Reputation Management Training</Link></li>
                                <li><Link to="/wordpress">WordPress Training</Link></li>
                                <li><Link to="/mobile_apps">Mobile Apps Training</Link></li>
                            </ul>
                        </aside>

                     

                    </div>
                </Col>

                <Col lg="12" md={12}>

<Row className=" mt-5 course-detailis-page-ul">
                <Col md={4} >
                    <div className="img-wrap">
                        <img src="img/course-details/mobile-apps-two.webp" alt="services" className="img-fluid"/>
                    </div>
                </Col>
                <Col md={8}>
                <p>App development training course in Patna beginners and experienced people learning from however dealing with different OSs. App Developers have to consider three main operating systems are Android, iOS and windows. App developer knowledge required to conduct bug fixing and preparation with recommended dealing with technical OS issues. And learning from how to choose the right app to develop and clearly defining with the target market. Here we provide for lots of authorized and best Mobile app development course training in Patna. Practical classroom and online training for android app development course classes around India within affordable app development training in Patna fees.</p>
              
                </Col>
            </Row>

            <div className="mt-5">
            <hr/>
            </div>
<Row className="justify-content-center">
    <Col lg="10">
    <div className="mt-3">
    <h3>(FAQ) about Mobile App Development</h3>
    </div>
            <Accordion className="mt-2">
      <Accordion.Item eventKey="0">
        <Accordion.Header> What do you mean by Mobile Application Development?</Accordion.Header>
        <Accordion.Body>
       <p>Mobile app development is the process of creating software applications that are intended to run on mobile devices.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> What are the different career opportunities for Mobile app developers?</Accordion.Header>
        <Accordion.Body>
      <p>With the ever-increasing market demand for mobile applications, the dearth for skilled mobile app developers is also increasing. Mobile application developers have the opportunity to work on different platforms, both for smartphones and tablets. Thus, a skilled mobile application developer is a highly sought-after profile, with a huge potential for career growth.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Who is eligible for this course? Is prior experience required?</Accordion.Header>
        <Accordion.Body>
        <p>The Mobile Application Development Online Training at FITA Academy is suitable for everyone, be it a beginner or an experienced professional. No prior experience is required. Any graduates -- freshers or experienced from other industries, can also learn this course. This course is also suitable for Web Developers, .Net professionals, Java Professionals and UI Developers who wish to improve their career opportunities.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>What happens If I miss one of my Classes?</Accordion.Header>
        <Accordion.Body>
        <p>In case you miss a class, kindly contact our support team and they will assist you in rescheduling the missed sessions without hassle.</p>
        </Accordion.Body>
      </Accordion.Item>


    </Accordion>

                            <div className="action-btns mt-4">
                                <Link to="/enroll_now" className="btn secondary-solid-btn mr-3">Enroll Now</Link>
                            </div>
            </Col>
            </Row>
</Col>
              
            </Row>
        </Container>
    </section>

    <div className="mb-5">
            <hr/>
            </div>
    <section className="address-courses">
      <Container className="container custom-container">
        <Row className=" align-items-center justify-content-center">
          <Col lg="9" md={10} className="mb-30">
            <div className="section-title animation-style2 text-center">

             <h2>Contact Us</h2>
            </div>

          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="4" md={6}>
            <div className="contact-landing">
              <i className="fa fa-map-marker"></i>
         <h4>Reach Us</h4>
         <p>Near Bihar Pharmacy College Gola Road, Bailey Rd, Patna, Bihar 801503
          </p>
        </div>
          </Col>
          <Col lg="4" md={6}>
            <div className="contact-landing">
              <i className="fa fa-phone"></i>
            <h4>Call Us</h4>
            <p><span class="fas fa-phone-alt mr-2"></span> <a href="tel:+91 7488444888"> +91 7488444888</a></p>
         
          </div>
          </Col>
          <Col lg="4" md={6}>
            <div className="contact-landing">
              <i className="fa fa-envelope"></i>
            <h4>Mail Us</h4>
           <p> <a href="mailto:info@indofast.org">info@indofast.org</a></p>
      
          </div>
          </Col>
        
        </Row>
      </Container>
    </section>

      </div>
    )
  }
}

export default Mobile_Apps
