import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

export class Director_Message extends Component {
    componentDidMount(){
        document.title = "Director Message | Indofast"
    }
  render() {
    return (
      <div>
                   <section className="hero-section gradient-overlay">
             <img src="img/banner/director.webp" alt=""/>
        <Container>
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">Director Message</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Director Message</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

    <section className="contact-details ptb-70">
    <Container>
		
        <Row className="director-massage-page">
            <Col lg="8" md={8}> 
                <div className="about_content">
                    <div className="teacher-details-content ">
                        <p className="chairman-mess">We are always dedicated and committed to give world class digital marketing training including complete seo/sem, ppc, website design and development, wordpress design, logo/graphics design, email marketing, affiliated marketing and many other training with live projects in Patna. You will get trained by the digital marketing industries experts around the world.</p>
                        <p>Digital Marketing (also Online Marketing, Internet Marketing or Web Marketing) is a collective name for marketing activity carried out online, as opposed to traditional marketing through print media, live promotions, TV and radio advertisement. The rapid growth of Digital Marketing Industry is a direct consequence of the global phenomenon that is the Internet, and effectiveness of Digital Marketing channels in generating revenue and awareness. Compared to traditional methods of advertising, Digital Marketing offers rather realistic costs (particularly important for small- and medium-size businesses and start-ups), accurate targeting and excellent reporting.</p>
                        <h5>I sincerely welcome to the Indofast Digital Marketing .</h5>
                        <h2>Mr. Vikash Vikal</h2>
                        <h6>Director</h6>
                 
                    </div>
                </div>
            </Col>
            <Col lg="4" md={4} >
                <div className="about_thumb director-massage">
                    <img className="img-fluid w100" src="img/director.webp" alt="1.jpg"/>
                    
                </div>
            </Col>
          
        </Row>
    
    </Container>
</section>

      </div>
    )
  }
}

export default Director_Message
