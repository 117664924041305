import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CountUp from 'react-countup';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'animate.css';
import {Helmet} from "react-helmet";

export class Home extends Component {
  render() {
 
    return (
      <div>
 <Helmet>
 <meta
      name="description"
      content="Web site created using create-react-app"/>
    <title>Home | Indofast</title>
  
</Helmet>



<div className="main-slider">
<OwlCarousel className="owl-theme custom-dot " loop={true} margin={0} nav={true} dots={false} autoplay={true} autoplayHoverPause={true} autoplayTimeout={4000} responsive={this.top_slider.responsive} animateOut="animate__fadeOut"
        animateIn="animate__fadeIn">
                            <div className="item">
                            <img className="d-block w-100" src="img/slider-2.webp" alt="First slide"/>
         
                            </div>
                            <div className="item">
                            <img className="d-block w-100" src="img/slider-1.webp" alt="First slide"/>
                            </div>
                        
                            </OwlCarousel>
                            </div>


<section className="promo-section ptb-70">
        <Container>
            <Row>
                <Col lg="4" md={4}>
                   
                        <div className="single-promo-2 single-promo-hover rounded text-center white-bg p-4 h-100">
                            <div className="circle-icon">
                                <span className="fa fa-users text-white"></span>
                            </div>
                            <h5>6 Months Course Duration</h5>
                            <p>60 Modules and 40 Tools with 6 Certifications advanced label training.</p>
                        </div>
                   
                </Col>
                <Col lg="4" md={4}>
                  
                        <div className="single-promo-2 single-promo-hover rounded text-center white-bg p-4 h-100">
                            <div className="circle-icon">
                                <span className="fa fa-users text-white"></span>
                            </div>
                            <h5>Industrial Internship</h5>
                            <p>Get Internship which can provide you additional skills of Digital Marketing.</p>
                        </div>
                   
                </Col>
                <Col lg="4" md={4}>
                  
                        <div className="single-promo-2 single-promo-hover rounded text-center white-bg p-4 h-100">
                            <div className="circle-icon">
                                <span className="fa fa-users text-white">
                                </span>
                            </div>
                            <h5>Get Ready for High Salary Job</h5>
                            <p>Work as Freelancing , Part & Full Time Always Get Desreid Salary after advanced skills.</p>
                        </div>
                   
                </Col>
            </Row>
        </Container>
    </section>


    <section className="about-us-section ptb-70 gray-light-bg">
        <Container>
            <Row>
                <Col lg="6" md={6}>
                    <div className="about-us-content-wrap">
                        <strong className="color-secondary">About Us</strong>
                        <h3>We Are The Best Digital Marketing Course in Patna & 100% Placement</h3>
                        <span className="animate-border mb-4"></span>
                        <p>Digital Marketing is the process of promoting your products or brands via one or more forms of electronic media. Digital Marketing involves the use of channels and methods that enable an organization to analyze marketing campaigns and understand what is working and what is not working. We are providing you the best Digital Marketing Program and Digital Marketing Training in Patna. Our Motto is that to make you employable and get you employed by complementing your education and accentuating your knowledge with a set of skills that employers are looking for. To prepare you with the right skill set for career challenges in the competition. Digital Marketing program will help for your growing career path. </p>
                        <div className="action-btns mt-4">
                            <Link to="/about" className="btn secondary-solid-btn mr-3">Start Now</Link>
                        </div>
                    </div>
                </Col>
                <Col lg="6" md={6}>
                    <div className="about-us-img">
                        <img src="img/t1-hero-img.svg" className="img-fluid custom-width" alt="hero"/>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>

    <section className="call-to-action-video">
    <Row className=" m-0">
        <Col lg="6" md={12} className="p-0">
            <div className="free-trial-image text-center">
                <img src="img/cta-2-girl-img.webp" alt="image" className="img-fluid"/>
                <a target='_blank' href="https://www.youtube.com/@indofast"
                   className="popup-youtube video-play-icon white-bip shadow"><span className="ti-control-play"></span> </a>
            </div>
        </Col>
        <Col lg="6" md={12} className="p-0">
            <div className="free-trial-content text-white">
                <h2 className="text-white">Grow Your career With Experienced digital marketing institute in Patna</h2>
                <p>Accelerate your career with hands-on training from a leading digital marketing institute in Patna.</p>
                <Link to="#" className="btn primary-solid-btn mt-3">Start Now</Link>
            </div>
        </Col>
    </Row>
</section>


<section className="services-section ptb-70 gray-light-bg">
        <Container>
            <Row className="justify-content-center">
                <Col md={8}>
                    <div className="section-heading text-center mb-3">
                        <strong className="color-secondary">Course</strong>
                        <h2>Our Course</h2>
                        <span className="animate-border mr-auto ml-auto mb-4"></span>
                        <p className="lead">We provide services that help in expanding your business worldwide, whether you are a start-up or big enterprise.</p>
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                      <img src="img/course/website-design-final.jpg" alt=""/>
                        <h5>Web Design Training</h5>
                        <Link to="/web_design" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/web-analysis.webp" alt=""/>
                        <h5>Web Analysis </h5>
                        <Link to="/web_analysis" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/content.webp" alt=""/>
                        <h5>Content Writing Training</h5>
                        <Link to="/content_writing" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/affiliate-marketing.webp" alt=""/>
                        <h5>Affiliate Marketing Training</h5>
                        <Link to="/affiliate_marketing" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/ppc.webp" alt=""/>
                        <h5>Pay Per Click (PPC) Training</h5>
                        <Link to="/pay_per_click" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/sem.webp" alt=""/>
                        <h5> (SEM) Training</h5>
                        <Link to="/sem" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/seo.webp" alt=""/>
                        <h5>Search Engine Optimization (SEO) Training</h5>
                        <Link to="/seo" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/smm.webp" alt=""/>
                        <h5> Social Media Marketing (SMM) Training</h5>
                        <Link to="/smm" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/reputation-management.webp" alt=""/>
                        <h5>Online Reputation Management Training</h5>
                        <Link to="/online_reputation_management" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/wordpress.webp" alt=""/>
                        <h5>WordPress Training</h5>
                        <Link to="/wordpress" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/mobile-app.webp" alt=""/>
                        <h5> Mobile Apps Training</h5>
                        <Link to="/mobile_apps" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col>
                {/* <Col lg="4" md={6}>
                    <div className="services-single text-center p-4 my-md-3 my-lg-3 my-sm-0 shadow-sm white-bg rounded">
                        <img src="img/course/other.webp" alt=""/>
                        <h5>Others</h5>
                        <Link to="/course_details" className="detail-link mt-4">View more <span
                                className="ti-arrow-right"></span></Link>
                    </div>
                </Col> */}
            </Row>
        </Container>
    </section>


    <section className="why-choose-us ptb-70">
        <Container>
            <Row className=" align-items-center justify-content-between">
                <Col md={7}>
                    <div className="why-choose-us-wrap">
                        <strong className="color-secondary">Why Choose Us</strong>
                        <h3>Why indofast Best Digital Marketing Institute is best for you?</h3>
                        <span className="animate-border mb-4"></span>
                        <p>indofast Digital Institute makes you Capable to achieve your Goals & make Your carrier successful. We make You Trained with live projects and able to handle Company Projects individually. We Guide you in the right way. We will give a Shape of your skills.</p>
                        <ul className="check-list-info">
                            <li>You can choose flexible hours for learning.
                            </li>
                            <li>You can learn in online class or you can attend offline classes.
                            </li>
                            <li>You are free to choose wide and specially designed modules of digital
                            </li>
                            <li>You are getting some important software for free with courses.
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col md={5}>
                    <div className="image-wrap">
                        <img src="img/second-section-1024x1024.webp" alt="why choose us" className="img-fluid"/>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>

    <section className="call-to-action ptb-70" style={{ backgroundImage:"url('img/ctg-bg-2.webp')", }}>
        <Container>
            <Row className="justify-content-center">
                <Col lg="8" md={8} >
                    <div className="call-to-action-content text-white text-center mb-4">
                        <h2 className="text-white mb-1">Indofast Digital 's Achievement 
                        </h2>
                      
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg="3" md={6} >
                    <div className="single-counter p-4 text-center text-white">
                        <span className="ti-medall-alt icon-md"></span>
                        <h1><CountUp
                    start={0}
                    end={7}
                    duration={3}
                />+</h1>
                       
                        <h5 className="text-white">Years Experience</h5>
                    </div>
                </Col>
                <Col lg="3" md={6}>
                    <div className="single-counter p-4 text-center text-white">
                        <span className="fa fa-users icon-md"></span>
                        <h1><CountUp
                    start={0}
                    end={50}
                    duration={3}
                />+</h1>
                       
                        <h5 className="text-white">Batches</h5>
                    </div>
                </Col>
                <Col lg="3" md={6}>
                    <div className="single-counter p-4 text-center text-white">
                        <span className="fa fa-users icon-md"></span>
                        <h1><CountUp
                    start={0}
                    end={500}
                    duration={3}
                />+</h1>
                       
                        <h5 className="text-white">Student</h5>
                    </div>
                </Col>
                <Col lg="3" md={6}>
                    <div className="single-counter p-4 text-center text-white">
                        <span className="fa fa-graduation-cap icon-md"></span>
                        <h1><CountUp
                    start={0}
                    end={100}
                    duration={3}
                />%</h1>
                       
                        <h5 className="text-white">Placement</h5>
                    </div>
                </Col>
             
            </Row>
        </Container>
    </section>

    <section className="work-process-section ptb-70 gray-light-bg">
        <Container>
            <Row className="justify-content-center">
                <Col lg="9" md={10}>
                    <div className="section-heading text-center">
                        <strong className="color-secondary">Training</strong>
                        <h2>Training process</h2>
                        <span className="animate-border mr-auto ml-auto mb-4"></span>
                        <p className="lead">Globally reinvent cross-unit human capital whereas virtual catalysts for change. Assertively
                            network accurate methods of empowerment rather than client-centered improvements.</p>
                    </div>
                </Col>
            </Row>
        
            <Row className="mt-5">
                <Col lg="5" md={12} className=" process-width">
                    <div className="process-box process-left">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="process-step-number">
                                    <strong>Step</strong>
                                    <h2 className="m-0">01</h2>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <h5> Enrollment</h5>
                                <p>Monotonectally optimize granular quality vectors vis-a-vis interdependent.</p>
                            </div>
                        </div>
                        <div className="process-line-l"></div>
                    </div>
                </Col>
                <Col md={2} className=" process-none"></Col>
                <Col lg="5" md={5} className="process-none">
                    <div className="process-point-right"></div>
                </Col>
            </Row>
        
            <Row>
                <Col lg="5" md={5} className="process-none">
                    <div className="process-point-left"></div>
                </Col>
                <Col md={2} className=" process-none"></Col>
                <Col lg="5" md={12} className="process-width">
                    <div className="process-box process-right">
                        <Row>
                            <Col md={5} >
                                <div className="process-step-number">
                                    <strong>Step</strong>
                                    <h2 className="m-0">02</h2>
                                </div>
                            </Col>
                            <Col md={7} >
                                <h5>Batch Assign</h5>
                                <p>Completely synthesize one-to-one interfaces vis-a-vis client-focused alignments.</p>
                            </Col>
                        </Row>
                        <div className="process-line-r"></div>
                    </div>
                </Col>
            </Row>
           
            <Row>
                <Col lg="5" md={12} className="process-width">
                    <div className="process-box process-left">
                        <Row>
                            <Col md={5}>
                                <div className="process-step-number">
                                    <strong>Step</strong>
                                    <h2 className="m-0">03</h2>
                                </div>
                            </Col>
                            <Col md={7}>
                                <h5>Training</h5>
                                <p>Progressively streamline cooperative sources whereas stand-alone channels.</p>
                            </Col>
                        </Row>
                        <div className="process-line-l"></div>
                    </div>
                </Col>
                <Col md={2} className=" process-none"></Col>
                <Col lg="5" md={5} className=" process-none">
                    <div className="process-point-right"></div>
                </Col>
            </Row>
          
            <Row>
                <Col lg="5" md={5} className=" process-none">
                    <div className="process-point-left"></div>
                </Col>
                <Col md={2} className="process-none"></Col>
                <Col lg="5" md={12} className="process-width">
                    <div className="process-box process-right">
                        <Row>
                            <Col md={5} >
                                <div className="process-step-number">
                                    <strong>Step</strong>
                                    <h2 className="m-0">04</h2>
                                </div>
                            </Col>
                            <Col md={7}>
                                <h5>Internship</h5>
                                <p>Objectively underwhelm one-to-one deliverables whereas impactful solutions. </p>
                            </Col>
                        </Row>
                        <div className="process-line-r"></div>
                    </div>
                </Col>
            </Row>
          
            <Row>
                <Col lg="5" md={12} className="process-width">
                    <div className="process-box process-left">
                        <Row>
                            <Col md={5} >
                                <div className="process-step-number">
                                    <strong>Step</strong>
                                    <h2 className="m-0">05</h2>
                                </div>
                            </Col>
                            <Col md={7}>
                                <h5>JOB Placement</h5>
                                <p>Interactively seize innovative platforms vis-a-vis ubiquitous portals.</p>
                            </Col>
                        </Row>
                        <div className="process-line-l"></div>
                    </div>
                </Col>
                <Col md={2} className=" custom-none"></Col>
                <Col lg="5" md={5} className="custom-none">
                    <div className="process-point-right process-last"></div>
                </Col>
            </Row>
          
        </Container>
    </section>

    <section className="testimonial-section ptb-100" style={{ backgroundImage:"url('img/testimonial-bg.webp')", }}>
        <Container>
            <Row className=" align-items-center">
                <Col lg="5" md={6}>
                    <div className="testimonial-heading text-white">
                        <h2 className="text-white">What Our student Say About Us</h2>
                        <span className="animate-border mb-4"></span>
                        <p>Our students consistently praise our institute for its expert instructors and hands-on approach, empowering them to excel in the dynamic field of digital marketing.</p>
                    </div>
                </Col>
                <Col lg="6" md={6} className="col-md-6 col-lg-6">
                    <div className="testimonial-content-wrap">
                        <img src="img/testimonial-arrow-top.webp" className="img-fluid testimonial-tb-shape shape-top"
                             alt="testimonial shape"/>
                       
                        <OwlCarousel className="owl-theme custom-dot testimonial-shape" loop={true} margin={0} nav={false} dots={true} autoplay={true} autoplayHoverPause={true} autoplayTimeout={2000} responsive={this.state.responsive} >
                            <div className="item">
                                <div className="testimonial-quote-wrap">
                                    <div className="media author-info mb-3">
                                        <div className="author-img mr-3">
                                            <img src="img/student.webp" alt="client" className="img-fluid"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h5 className="mb-0 text-white">John Charles</h5>
                                           
                                        </div>
                                        <span className="fas fa-quote-right icon-md text-white"></span>
                                    </div>
                                    <div className="client-say text-white view-more-view-less">
                                        <p>Interactively optimize
                                            fully researched expertise vis-a-vis plug-and-play relationships.
                                            Intrinsicly
                                            develop viral core competencies for fully tested customer service.
                                            Enthusiastically create next-generation growth strategies and.</p>
                                            <input type="checkbox" className="check"/>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial-quote-wrap">
                                    <div className="media author-info mb-3">
                                        <div className="author-img mr-3">
                                            <img src="img/student.webp" alt="client" className="img-fluid"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h5 className="mb-0 text-white">Arabella Ora</h5>
                                           
                                        </div>
                                        <span className="fas fa-quote-right icon-md text-white"></span>
                                    </div>
                                    <div className="client-say text-white view-more-view-less">
                                        <p>Rapidiously develop user
                                            friendly growth strategies after extensive initiatives. Conveniently grow
                                            innovative benefits through fully tested deliverables. Rapidiously utilize
                                            focused platforms through end-to-end schemas.</p>
                                            <input type="checkbox" className="check"/>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial-quote-wrap">
                                    <div className="media author-info mb-3">
                                        <div className="author-img mr-3">
                                            <img src="img/student.webp" alt="client" className="img-fluid"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h5 className="mb-0 text-white">Jeremy Jere</h5>
                                           
                                        </div>
                                        <span className="fas fa-quote-right icon-md text-white"></span>
                                    </div>
                                    <div className="client-say text-white view-more-view-less">
                                        <p>Objectively synthesize
                                            client-centered e-tailers for maintainable channels. Holisticly administrate
                                            customer directed vortals whereas tactical functionalities. Energistically
                                            monetize reliable imperatives through client-centric best practices.
                                            Collaboratively.</p>
                                            <input type="checkbox" className="check"/>
                                    </div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="testimonial-quote-wrap">
                                    <div className="media author-info mb-3">
                                        <div className="author-img mr-3">
                                            <img src="img/student.webp" alt="client" className="img-fluid"/>
                                        </div>
                                        <div className="media-body text-white">
                                            <h5 className="mb-0 text-white">John Charles</h5>
                                          
                                        </div>
                                        <span className="fas fa-quote-right icon-md text-white"></span>
                                    </div>
                                    <div className="client-say text-white view-more-view-less">
                                        <p>Enthusiastically innovate
                                            B2C data without clicks-and-mortar convergence. Monotonectally deliver
                                            compelling testing procedures vis-a-vis B2B testing procedures. Competently
                                            evisculate integrated resources whereas global processes.
                                            Enthusiastically.</p>
                                            <input type="checkbox" className="check"/>
                                    </div>
                                </div>
                            </div>
                            </OwlCarousel>
                       
                        <img src="img/testimonial-arrow-bottom.webp" className="img-fluid testimonial-tb-shape shape-bottom"
                             alt="testimonial shape"/>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>

    <section className="our-blog-section ">
    <Container>
        <Row className="justify-content-center">
            <Col lg="9" md={10}>
                <div className="section-heading text-center mb-5">
                    <strong className="color-secondary"> Blog</strong>
                    <h2>Our Latest Blog</h2>
                    <span className="animate-border mr-auto ml-auto mb-4"></span>
                    
                </div>
            </Col>
        </Row>
        <Row className="justify-content-center">
            <Col lg="4" md={6} >
                <div className="single-blog-card card border-0 shadow-sm">
                    <div className="blog-img position-relative">
                        <img src="img/blog/1.webp" className="card-img-top" alt="blog"/>
                        <div className="meta-date">
                            <strong>15</strong>
                            <small>Jan</small>
                        </div>
                    </div>
                    <div className="card-body">
                   
                        <h3 className="h5 mb-2 card-title"><Link to="#"> Why Learn Digital Marketing? </Link></h3>
                       
                        <Link to="#" className="detail-link">Read more <span className="ti-arrow-right"></span></Link>
                    </div>
                </div>
            </Col>
            <Col lg="4" md={6}>
                <div className="single-blog-card card border-0 shadow-sm">
                    <div className="blog-img position-relative">
                        <img src="img/blog/new-blog-1.webp" className="card-img-top" alt="blog"/>
                        <div className="meta-date">
                            <strong>15</strong>
                            <small>Jan</small>
                        </div>
                    </div>
                    <div className="card-body">
                  
                        <h3 className="h5 mb-2 card-title"><Link to="#">The Right seo strategies </Link></h3>
                       
                        <Link to="#" className="detail-link">Read more <span className="ti-arrow-right"></span></Link>
                    </div>
                </div>
            </Col>
            <Col lg="4" md={6}>
                <div className="single-blog-card card border-0 shadow-sm">
                    <div className="blog-img position-relative">
                        <img src="img/blog/new-blog-2.webp" className="card-img-top" alt="blog"/>
                        <div className="meta-date">
                            <strong>15</strong>
                            <small>Jan</small>
                        </div>
                    </div>
                    <div className="card-body">
                   
                        <h3 className="h5 mb-2 card-title"><Link to="#">Social media marketing</Link></h3>
                       
                        <Link to="#" className="detail-link">Read more <span className="ti-arrow-right"></span></Link>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
</section>

<section className="call-to-action py-5 gray-light-bg">
        <div className="container">
            <div className="row justify-content-around align-items-center">
                <div className="col-md-9">
                    <div className="subscribe-content">
                        <h3 className="mb-1">We Are The Best Digital Marketing Institute in Patna</h3>
                       
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="action-btn text-lg-right text-sm-left">
                    <Link to="/contact" className="btn secondary-solid-btn">Contact With Us </Link>
                       
                    </div>
                </div>
            </div>
        </div>
    </section>

      </div>
    )
  }
  state= {
    responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 1,
        },
        600: {
            items: 1,
        },
        1000: {
            items: 1,
        },
    },
}

top_slider= {
    responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 1,
        },
        600: {
            items: 1,
        },
        1000: {
            items: 1,
        },
    },
}

}

export default Home
