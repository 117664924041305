import React, {useState} from 'react'; 
import Container from 'react-bootstrap/Container';
import { NavLink } from "react-router-dom";

const Header_To =() => {

    const [isMobile, setIsMobile] = useState(false);
    return (
      <div>



        <header className="header home-page-header">
   
   <nav className="navbar navbar-expand-lg fixed-top">
       <Container>
           <NavLink className="navbar-brand" to="/" onClick={() => setIsMobile(false)}>
               <img src="img/logo-color.png" alt="logo" className="img-fluid"/>
           </NavLink>
       
           <button className="navbar-toggler" type="button" onClick={() => setIsMobile(!isMobile)}>
           {isMobile ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
           </button>
           <div className={isMobile ? "Nav-links-mobile" : "collapse navbar-collapse h-auto"}
               >
               <ul className="navbar-nav ml-auto menu">
                   <li><NavLink to="/" onClick={() => setIsMobile(false)}>Home</NavLink></li>
                
                   <li><NavLink to="javascript:" className="dropdown-toggle">About Us</NavLink>
                       <ul className="sub-menu">
                           <li><NavLink to="/about" onClick={() => setIsMobile(false)}>About Us</NavLink></li>
                           <li><NavLink to="/director_message" onClick={() => setIsMobile(false)}>Director Message</NavLink></li>
                       </ul>
                   </li>
                   <li><NavLink to="/courses" onClick={() => setIsMobile(false)}>Our Courses</NavLink></li>
                   <li><NavLink to="javascript:" className="dropdown-toggle">Gallery</NavLink>
                       <ul className="sub-menu">
                           <li><NavLink to="/gallery_categories" onClick={() => setIsMobile(false)}>Gallery</NavLink>
                           </li>
                           <li><NavLink to="/video" onClick={() => setIsMobile(false)}>Video</NavLink></li>
                       </ul>
                   </li>
                   <li><NavLink to="/faq" onClick={() => setIsMobile(false)}>Faq</NavLink></li>
                   <li><NavLink to="/blog" onClick={() => setIsMobile(false)}>Blog</NavLink></li>
                   <li><NavLink to="/contact" onClick={() => setIsMobile(false)}>Contact Us</NavLink></li>
                   <li className="bg-color-apply" onClick={() => setIsMobile(false)}><NavLink to="/enroll_now" className="btn check-btn btn-header" onClick={() => setIsMobile(false)}>Enroll Now </NavLink></li>
               </ul>
           </div>
       </Container>
   </nav>
</header>

      </div>
    )
 
}

export default Header_To