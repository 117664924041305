import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';

export class About extends Component {
    componentDidMount(){
        document.title = "About Us | Indofast"
    }
  render() {
    return (
      <div>
             <section className="hero-section gradient-overlay">
             <img src="img/banner/about.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">About Us</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">About Us</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

    <section className="promo-section ptb-70">
        <Container>
            <Row>
                <Col lg="4" md={4}>
                   
                        <div className="single-promo-2 single-promo-hover rounded text-center white-bg p-4 h-100">
                            <div className="circle-icon">
                                <span className="fa fa-users text-white"></span>
                            </div>
                            <h5>6 Months Course Duration</h5>
                            <p>60 Modules and 40 Tools with 6 Certifications advanced label training.</p>
                        </div>
                   
                </Col>
                <Col lg="4" md={4}>
                  
                        <div className="single-promo-2 single-promo-hover rounded text-center white-bg p-4 h-100">
                            <div className="circle-icon">
                                <span className="fa fa-users text-white"></span>
                            </div>
                            <h5>Industrial Internship</h5>
                            <p>Get Internship which can provide you additional skills of Digital Marketing.</p>
                        </div>
                   
                </Col>
                <Col lg="4" md={4}>
                  
                        <div className="single-promo-2 single-promo-hover rounded text-center white-bg p-4 h-100">
                            <div className="circle-icon">
                                <span className="fa fa-users text-white">
                                </span>
                            </div>
                            <h5>Get Ready for High Salary Job</h5>
                            <p>Work as Freelancing , Part & Full Time Always Get Desreid Salary after advanced skills.</p>
                        </div>
                   
                </Col>
            </Row>
        </Container>
    </section>

    <section className="about-us-section ptb-70 gray-light-bg">
        <Container>
            <Row>
                <Col lg="6" md={6}>
                    <div className="about-us-content-wrap">
                        <strong className="color-secondary">About Us</strong>
                        <h3>We Are The Best Digital Marketing Course in Patna & 100% Placement</h3>
                        <span className="animate-border mb-4"></span>
                        <p>We are a young, passionate and expert digital marketing training company based in the capital of Bihar, Patna. We are always dedicated and committed to give world class digital marketing training including complete seo/sem, ppc, website design and development, wordpress design, logo/graphics design, email marketing, affiliated marketing and many other training with live projects in Patna. You will get trained by the digital marketing industries experts around the world.</p>
                       
                    </div>
                </Col>
                <Col lg="6" md={6}>
                    <div className="about-us-img">
                        <img src="img/about-img.webp" className="img-fluid custom-width" alt="hero"/>
                    </div>
                </Col>
                <Col lg="12">
                <div className="about-bottom-content">
                <p>Digital Marketing (also Online Marketing, Internet Marketing or Web Marketing) is a collective name for marketing activity carried out online, as opposed to traditional marketing through print media, live promotions, TV and radio advertisement.</p>
                <p>The rapid growth of Digital Marketing Industry is a direct consequence of the global phenomenon that is the Internet, and effectiveness of Digital Marketing channels in generating revenue and awareness. Compared to traditional methods of advertising, Digital Marketing offers rather realistic costs (particularly important for small- and medium-size businesses and start-ups), accurate targeting and excellent reporting.</p>
                </div>
                </Col>
            </Row>
        </Container>
    </section>

    <section className="call-to-action ptb-70" style={{ backgroundImage:"url('img/ctg-bg-2.webp')", }}>
        <Container>
            <Row className="justify-content-center">
                <Col lg="8" md={8}>
                    <div className="call-to-action-content text-white text-center mb-4">
                        <h2 className="text-white mb-1">Indofast Digital 's Achievement 
                        </h2>
                      
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg="3" md={6}>
                    <div className="single-counter p-4 text-center text-white">
                        <span className="ti-medall-alt icon-md"></span>
                        <h1><CountUp
                    start={0}
                    end={7}
                    duration={3}
                />+</h1>
                       
                        <h5 className="text-white">Years Experience</h5>
                    </div>
                </Col>
                <Col lg="3" md={6}>
                    <div className="single-counter p-4 text-center text-white">
                        <span className="fa fa-users icon-md"></span>
                        <h1><CountUp
                    start={0}
                    end={50}
                    duration={3}
                />+</h1>
                       
                        <h5 className="text-white">Batches</h5>
                    </div>
                </Col>
                <Col lg="3" md={6}>
                    <div className="single-counter p-4 text-center text-white">
                        <span className="fa fa-users icon-md"></span>
                        <h1><CountUp
                    start={0}
                    end={500}
                    duration={3}
                />+</h1>
                       
                        <h5 className="text-white">Student</h5>
                    </div>
                </Col>
                <Col lg="3" md={6}>
                    <div className="single-counter p-4 text-center text-white">
                        <span className="fa fa-graduation-cap icon-md"></span>
                        <h1><CountUp
                    start={0}
                    end={100}
                    duration={3}
                />%</h1>
                       
                        <h5 className="text-white">Placement</h5>
                    </div>
                </Col>
             
            </Row>
        </Container>
    </section>

    <section className="about-us-section ptb-70 gray-light-bg">
        <Container>
            <Row>
                <Col lg="6" md={6}>
                    <div className="about-us-content-wrap">
                        <h3>Our mission</h3>
                        <span className="animate-border mb-4"></span>
                        <p>We are on a mission to make you employable and get you employed by complementing your education and accentuating your knowledge with a set of skills that employers are looking for. To prepare you with the right skill set for career challenges in the competition – driven industry is our mission.</p>
                       
                    </div>
                </Col>
                <Col lg="6" md={6}>
                    <div className="about-us-img">
                        <img src="img/our-mission.webp" className="img-fluid custom-width" alt="hero"/>
                    </div>
                </Col>
             
            </Row>
            <Row>
            <Col lg="6" md={6}>
                    <div className="about-us-img">
                        <img src="img/our-vision.webp" className="img-fluid custom-width" alt="hero"/>
                    </div>
                </Col>
                <Col lg="6" md={6}>
                    <div className="about-us-content-wrap">
                        <h3>Our Vision</h3>
                        <span className="animate-border mb-4"></span>
                        <p>Our vision of a Healthy Economy where every youth is employable and gets employed is the inspiration for our mission “Young India at Work” we believe a nation grows with economic boom, with results from everyone’s contribution. So, every youth needs to be employable and get employed in order to contribute to the growth of economy.</p>
                       
                    </div>
                </Col>
            <Col lg="12">
            <div className="about-bottom-content">
            <p>Our vision is rooted in the collective potential of the youth of India, which DIGITAL MARKETING programs can nurture, enrich. Perfect and leverage for high-level employability of individuals. Our employability – enhancing Digital Marketing programs are inspired by a greater vision, a greater purpose and a greater cause.</p>
            <p>Our vision at Digital Marketing Course connects with thousands of dreams of employment in deferent niches. Our vision of “young India at work” is a statement on the power of innovation and competence that confidence generates. With this vision in focus, we are committed to make India a nation of skilled individuals who are confidently employable.</p>
            </div>
            </Col>
             
            </Row>
        </Container>
    </section>

      </div>
    )
  }
}

export default About
