import React, { Component } from 'react'
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom'

export class Enroll_Now extends Component {
    
  componentDidMount(){
    document.title = "Enroll Now | Indofast"
}
  render() {
    return (
      <div>
         <section className="hero-section gradient-overlay">
             <img src="img/banner/enroll_now.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content ">
                        <h1 className="text-white mb-0">Enroll Now</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Enroll Now</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

<Container className="mb-5 mt-5">
    <Row className="justify-content-center">
        <Col lg="10">
    <Form className="enroll-form-bg">
        <Row>
        <Col lg="6">
      <Form.Group>
        <Form.Label>Name *</Form.Label>
        <Form.Control type="text" placeholder="Enter Name" />
      </Form.Group>
      </Col>
      <Col lg="6">
      <Form.Group>
        <Form.Label>Email *</Form.Label>
        <Form.Control type="email" placeholder="Enter Email" />
      </Form.Group>
      </Col>
      <Col lg="6">
      <Form.Group>
        <Form.Label>Number *</Form.Label>
        <Form.Control type="number" placeholder="Enter Number" />
      </Form.Group>
      </Col>
      <Col lg="6">
      <Form.Label>Our Courses *</Form.Label>
      <Form.Select aria-label="Default select example" className="form-control">
      <option value="digital marketing">Digital Marketing</option>
     
    </Form.Select>
    </Col>

    <Col lg="12">
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Address *</Form.Label>
        <Form.Control as="textarea" rows={4} placeholder="Enter Address" />
      </Form.Group>
    </Col>

    </Row>
    <div className='text-center'>
    <Button type="submit" className="btn secondary-solid-btn">
        Submit
      </Button>
      </div>
    </Form>
    </Col>
    </Row>
    </Container>

      </div>
    )
  }
}

export default Enroll_Now
