import React, { Component } from 'react'
import LightGallery from 'lightgallery/react';
import Container from 'react-bootstrap/Container';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import { Link } from 'react-router-dom'
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

export class Gallery extends Component {
    componentDidMount(){
        document.title = "Gallery | Indofast"
    }
  render() {
 

    const onInit = () => {
        console.log('');
    };
    return (
      <div>
             <section className="hero-section gradient-overlay">
             <img src="img/banner/gallery.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">Photo Gallery</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Gallery</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

           <Container>
        <LightGallery
            onInit={onInit}
            speed={500}
            plugins={[lgThumbnail, lgZoom]}
        > 


            <a href="img/gallery/a1.webp">
                <img src="img/gallery/a1.webp" />
            </a>
         
            <a href="img/gallery/a2.webp">
                <img src="img/gallery/a2.webp" />
            </a>
   
            <a href="img/gallery/a3.webp">
                <img src="img/gallery/a3.webp" />
            </a>
            <a href="img/gallery/a4.webp">
                <img src="img/gallery/a4.webp" />
            </a>
            <a href="img/gallery/a5.webp">
                <img src="img/gallery/a5.webp" />
            </a>
            <a href="img/gallery/a6.webp">
                <img src="img/gallery/a6.webp" />
            </a>
            <a href="img/gallery/a7.webp">
                <img src="img/gallery/a7.webp" />
            </a>
            <a href="img/gallery/a8.webp">
                <img src="img/gallery/a8.webp" />
            </a>
            <a href="img/gallery/a9.webp">
                <img src="img/gallery/a9.webp" />
            </a>
            <a href="img/gallery/a10.webp">
                <img src="img/gallery/a10.webp" />
            </a>
            <a href="img/gallery/a11.webp">
                <img src="img/gallery/a11.webp" />
            </a>
            <a href="img/gallery/a12.webp">
                <img src="img/gallery/a12.webp" />
            </a>
            <a href="img/gallery/a13.webp">
                <img src="img/gallery/a13.webp" />
            </a>
            <a href="img/gallery/a14.webp">
                <img src="img/gallery/a14.webp" />
            </a>
            <a href="img/gallery/a15.webp">
                <img src="img/gallery/a15.webp" />
            </a>
            <a href="img/gallery/a16.webp">
                <img src="img/gallery/a16.webp" />
            </a>
            <a href="img/gallery/a17.webp">
                <img src="img/gallery/a17.webp" />
            </a>
        </LightGallery>
        </Container>
      </div>
    )
  }
}

export default Gallery
