import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom'

export class Blog_Details extends Component {
    componentDidMount(){
        document.title = "Blog | Indofast"
    }
  render() {
    return (
      <div>
       <section className="hero-section gradient-overlay">
             <img src="img/banner/blog-details.webp" alt=""/>
        <Container>
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">Blog Details</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Blog</li>
                            </ol>
                        </div>
                    </div>
        </Container>
    </section>


    <div className="module ptb-100">
        <Container>
            <Row>
                <Col lg="8" md={8}>
                  
                    <article className="post">
                        <div className="post-preview"><img src="img/blog/1-w.webp" alt="article" className="img-fluid"/></div>
                        <div className="post-wrapper">
                            <div className="post-header">
                                <h1 className="post-title">Why Learn Digital Marketing?</h1>
                                <ul className="post-meta">
                                    <li>Jan 15, 2024</li>
                                  
                                    <li><a href="#">Indofast</a></li>
                                </ul>
                            </div>
                            <div className="post-content">
                                <p>Digital media has become a direct channel between a brand and its consumers. With more and more people spending more and more time on various digital platforms, brands are reaching out via these channels to market themselves, at the same time develop a personal connect with the audience. Digital channels have an edge over traditional channels and methods in its reach, targeting, measurability, cost effectiveness and much more. Brands now realizing the immense potential in this opportunity is exploring it further by allocating larger budget, trying out new channels etc. This, in turn, drives up the demand for trained professionals to carry out these tasks. A completely new vertical and a whole new set of jobs have been created because of this trend, say digital marketer, analyst, strategist, Social media marketer, SEO analyst and more. Though the sector demands a large number of well-trained professionals, the truth is the supply is hardly meeting the demand. To fill this gap you as a marketer must undergo formal training and associated certifications in digital marketing, which will help you achieve a well-paid, challenging career in a sector with immense growth potential.</p>
                                <blockquote className="blockquote">
                                    <p>As good as all that sounds, you should have a clear objective why you want to learn or even master digital lumens and your efforts must be targeted towards that ultimate goal. Let us see different reasons why you should take up this as your career.</p>
                                   
                                </blockquote>
                                <p>If you are a fresher and are at crossroads of choosing a boring career in your field hesitantly because of lack of options, know that there is always a much more interesting career path to go down on, digital marketing. Whatever be your graduation qualification, digital/internet marketing is a skill you can acquire with the right training. Another trend our generations sees is how we completely change career mid-way through, unlike previous generations who kept the same job in the same organization all their life. If you are in that phase where you think you need a change, digital marketing could be exactly what you need. Learn something new, interesting and challenging. No more boring everyday tasks, let your creative juices flow and every task be something new. But if you do enjoy what you do, but is just looking for some progress in your career or pay scale, digital marketing could be the answer to your instant career growth. In sales, marketing, advertising, PR, IT related jobs and more, with an additional skill set, you could make better money than your peers and also get faster promotions. If you are an entrepreneur or a business owner, digital marketing is something you cannot avoid. A much cheaper and more effective alternative to traditional marketing, digital marketing is ideal for small business owners to target and reach their customers. Instead of hiring a full-time digital marketing employee or freelancing the job, why not spend a little time, learn the trade and market your business yourself with a level of commitment and understanding of the business that no one else would ever have.</p>
                                <p>If you need to stay at home for some reason be it a career break or a newborn child, but hate sitting idle, freelance digital marketing might be the perfect solution. With proper training and experience, you can work as a freelance digital/internet marketer, social media marketer, content writer and more and earn good money, while being at home. A good digital marketing training course must include SEO techniques and analysis, running own campaigns, analyzing and managing them, running ads, targeting and measuring them and more, so that they will be equipped with all essential skill sets for a digital marketer. The various essential modules that need to be covered are SEO, SMO, SMM, PPC, Facebook marketing and more. However, an ideal course must also give you the flexibility to choose what you want to learn, to what extent and when, so that you get the exact training that you need, at your convenience. A good training course might be your first step towards a prosperous career.</p>
                          
                              
                            </div>
                        
                        </div>
                    </article>
                    

                    
                </Col>
                <Col lg="4" md={4}>
                    <div className="sidebar-right p-4">

                      
                        <aside className="widget widget-search">
                            <form>
                                <input className="form-control" type="search" placeholder="Type Search Words"/>
                                <button className="search-button" type="submit"><span className="ti-search"></span></button>
                            </form>
                        </aside>


                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h6>Categories</h6>
                            </div>
                            <ul>
                                <li><Link to="/blog">Web Design <span className="float-right">12</span></Link></li>
                                <li><Link to="/blog">Pay Per Click <span className="float-right">16</span></Link></li>
                                <li><Link to="/blog">Content Writing <span className="float-right">10</span></Link></li>
                                <li><Link to="/blog">Affiliate Marketing <span className="float-right">14</span></Link></li>
                                <li><Link to="/blog">Search Engine Optimization <span className="float-right">18</span></Link></li>
                            </ul>
                        </aside>

                        <aside className="widget widget-recent-entries-custom">
                            <div className="widget-title">
                                <h6>Recent Posts</h6>
                            </div>
                            <ul>
                                <li className="clearfix">
                                    <div className="wi"><Link to="/blog_details"><img src="img/blog/1.webp" alt="recent post" className="img-fluid rounded"/></Link></div>
                                    <div className="wb"><Link to="/blog_details">Why Learn Digital Marketing?</Link><span className="post-date">Jan 8, 2024</span></div>
                                </li>
                                <li className="clearfix">
                                    <div className="wi"><Link to="/blog_details"><img src="img/blog/new-blog-1.webp" alt="recent post" className="img-fluid rounded"/></Link></div>
                                    <div className="wb"><Link to="/blog_details">The Right seo strategies</Link><span className="post-date">Jan 14, 2024</span></div>
                                </li>
                                <li className="clearfix">
                                    <div className="wi"><Link to="/blog_details"><img src="img/blog/new-blog-2.webp" alt="recent post" className="img-fluid rounded"/></Link></div>
                                    <div className="wb"><Link to="/blog_details">Social media marketing</Link><span className="post-date">Jan 18, 2024</span></div>
                                </li>
                            </ul>
                        </aside>

                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h6>Newsletter</h6>
                            </div>
                            <p>Enter your email address below to subscribe to my newsletter</p>
                            <form action="#" method="post"
                                  className=" d-md-block d-lg-block">
                                <input type="text" className="form-control input" id="email-footer" name="email"
                                       placeholder="info@yourdomain.com" />
                                <button type="submit" className="btn secondary-solid-btn btn-block btn-not-rounded mt-3">Subscribe</button>
                            </form>
                        </aside>

                    
                    </div>
                </Col>
            </Row>
        </Container>
    </div>


      </div>
    )
  }
}

export default Blog_Details
