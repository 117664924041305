import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';


export class Faq extends Component {
  componentDidMount(){
    document.title = "Faq | Indofast"
}
  render() {
    return (
      <div>
                  <section className="hero-section gradient-overlay">
             <img src="img/banner/faq.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">Faq</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Faq</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

<section className="promo-section gray-light-bg-new ptb-100">
    <Container>
    <Row>
                <Col lg="12" className="text-center">
                    <div class="section-heading mb-5">
                        <h2>Frequently Asked Question - Digital Marketing Training</h2>
                        
                    </div>
                </Col>
            </Row>
            <Row className="justify-content-center">
        <Col lg="11">
        <Accordion className="bg-color-faq">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Who can join the digital marketing training programme?</Accordion.Header>
        <Accordion.Body>
         <ul>
          <li>Digital Marketing program will help you for your growing career path.</li>
          <li>If you are student than, you can build your rewarding career in digital marketing industry.</li>
          <li>If you are a marketing professional than, Digital Marketing training will help you grow your career.</li>
          <li>If you are an entrepreneur than, Digital Marketing will be able to grow your business step by step.</li>
          <li>If you are a sales professional than Digital Marketing will be relevant for you.</li>
          <li>If you are business head than, you should learn Digital Marketing for yourself to connect to your clients directly.</li>
          <li>If you are an IT professional than, Digital marketing will relevant for your profile.</li>
          <li>If you are an HR professional than, Digital Marketing will relevant for you as well.</li>
          <li>If you have already into Digital Marketing than, It will accelerate your career growth if you expand your skill set?</li>
         </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Why should I learn from Indofast Digital Media?</Accordion.Header>
        <Accordion.Body>
         <p>Indofast Digital Media created a platform of Digital Environment for those candidates who want to make a dynamic profile in Digital Marketing because we have 10 years experience in Digital Marketing. We provide dynamic training program of Digital Marketing this training program will help our students to achieve their goals in the right time for the right path.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>What are the career opportunities in Digital Marketing?</Accordion.Header>
        <Accordion.Body>
         <p>A digital marketing professional, or specialist, is often responsible for developing the strategy used in marketing a company's product online, utilizing such techniques in the field as social media, Web analytics, e-mail marketing and search engine optimization, among others.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>Who all can take-up Digital Marketing Program?</Accordion.Header>
        <Accordion.Body>
         <p>The instructor for the program will be Digital marketing trainer. All our faculty are experts from the industry having an experience of more than 5 years.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header>Do you help us to get Google Certification?</Accordion.Header>
        <Accordion.Body>
         <p>No, You can apply for online exam conducted by Google itself at anytime. We will guide you for Google certification process.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header>I can't decide a Program that suits me? What do I do now?</Accordion.Header>
        <Accordion.Body>
         <p>We are a training provider for Digital Media and we can only say that if you are educated but unemployed than you can choose Digital Media Promotion training program it will support you to fulfill your career goals and you can make a digital career in this field.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6">
        <Accordion.Header>How qualified is the faculty/trainers of your institute?</Accordion.Header>
        <Accordion.Body>
         <p>We are working in Digital Media and we know that which type of knowledge works here for our students because we are making a dynamic platform for our students who will work digitally in the market area so our team member and faculty member all are dynamic they are experienced in Digital Media and you will really find your place with us.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header>Do you provide any training material?</Accordion.Header>
        <Accordion.Body>
         <p>Yes, We will Provide you each and every material related to the Program free of cost.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="8">
        <Accordion.Header>What is the duration of your Digital marketing Program?</Accordion.Header>
        <Accordion.Body>
         <p>Our Digital Marketing Training durations are 3 months only.</p>
        </Accordion.Body>
      </Accordion.Item>
      
      <Accordion.Item eventKey="9">
        <Accordion.Header>What are the qualifications required to do the Program?</Accordion.Header>
        <Accordion.Body>
         <p>Any Graduate</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="10">
        <Accordion.Header>Does your institute provide 100% Placement Guarantee?</Accordion.Header>
        <Accordion.Body>
         <p>Yes, We will provide 100% placement assistance guarantee, after successful completion of training. Our training program itself is Job oriented training program.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="11">
        <Accordion.Header>What would be the salary for a Digital Marketing Professional?</Accordion.Header>
        <Accordion.Body>
         <p>Indian Companies are offering Salary range of 1 Lakh to 2 Lakh per annum initially as a Digital marketing Professional.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="12">
        <Accordion.Header>I have got experience in traditional marketing; Can I shift my career to digital marketing?</Accordion.Header>
        <Accordion.Body>
         <p>Yes, Ofcourse you can Shift</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="13">
        <Accordion.Header>Will I get practical experience?</Accordion.Header>
        <Accordion.Body>
         <p>Yes, Our complete training program is based on theoritical & Practical approach</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="14">
        <Accordion.Header>Will I become an expert when I go through these Programs?</Accordion.Header>
        <Accordion.Body>
         <p>Yes you will be expert it’s all depend on your performance and concentration.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="15">
        <Accordion.Header>What are the differences between your Program and that of other institutes?</Accordion.Header>
        <Accordion.Body>
         <p>We have 8 years experience in digital marketing fee and we have an golden opportunities for students who is unemployed because after complete this training program we will provide job for them and our training program fees are also deferent from other industries.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="16">
        <Accordion.Header>What is the future of the digital marketing training?</Accordion.Header>
        <Accordion.Body>
         <p>The future scope of the Digital Marketing industry is that there will be more points of contact. Digital marketing is a booming industry. You will have to work with the analytical part of a website. You need to implement the latest SEO, PPC and SMO strategy.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="17">
        <Accordion.Header>Do I get the job assistance from your institute after the training program?</Accordion.Header>
        <Accordion.Body>
         <p>We are providing job also after complete this training.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="18">
        <Accordion.Header>Do I need to have strong IT skills?</Accordion.Header>
        <Accordion.Body>
         <p>You can say yes but if u have only little knowledge about IT while doing this training program we will trained you as much as you need. </p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="19">
        <Accordion.Header>I am a working professional, can I ask for Flexible Timing?</Accordion.Header>
        <Accordion.Body>
         <p>Yes you can set a timing according to your work </p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="20">
        <Accordion.Header>I am a student, how this Program can help me?</Accordion.Header>
        <Accordion.Body>
         <p>If you are a student and you are unemployed than you can chose this training for your future career. This training program will help you to achieve your career goals if you want be your career in Digital Marketing Field.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="21">
        <Accordion.Header>I am a freelancer working from home, how this Program can help me?</Accordion.Header>
        <Accordion.Body>
         <p>If you are freelancer worker than you have time in your hand so you can also do this training it will help you to do more in your future and this training can also help you for your working nature also.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="22">
        <Accordion.Header>I have a new business startup, how this Program can help me?</Accordion.Header>
        <Accordion.Body>
         <p>If you have a new business than this training will help to reach to your customers so easily you can implement your training experience in your business to increase your clients base and promote your business in networking field.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="23">
        <Accordion.Header>Due to unavoidable reasons if I missed few classes, then can I join the backup class to cover it up with-out additional charges?</Accordion.Header>
        <Accordion.Body>
         <p>We will provide this facility for your better knowledge.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="24">
        <Accordion.Header>Is there a job guarantee?</Accordion.Header>
        <Accordion.Body>
         <p>Yes we provide you job after this training program</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="25">
        <Accordion.Header>Is the Program theoretical or practical?</Accordion.Header>
        <Accordion.Body>
         <p>We will provide digital marketing training on live project</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="26">
        <Accordion.Header>Who is the Trainer for Digital Marketing?</Accordion.Header>
        <Accordion.Body>
         <p>We have a very dynamic faculty for digital marketing training program. They have more than 7 years of experience in Digital Marketing program.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="27">
        <Accordion.Header>Is this Program classroom or online?</Accordion.Header>
        <Accordion.Body>
         <p>We create a digital environment for our candidate and make them online user friendly through our training and our training programs are totally online.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="28">
        <Accordion.Header>Do I need to know website designing and Programming?</Accordion.Header>
        <Accordion.Body>
         <p>If you know website designing and programming so it will help you to enhance your skill but doesn’t matter what you know we have responsibility to enhance your knowledge in digital marketing whether you know web designing or not.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="29">
        <Accordion.Header>What is the eligibility required to do the Digital Marketing Programs?</Accordion.Header>
        <Accordion.Body>
         <p>Digital Marketing Training is a online training program if you are intermediate and you are capable to learn digital marketing than you can join this program.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="30">
        <Accordion.Header>Is it possible to do work from home after I complete the Program? </Accordion.Header>
        <Accordion.Body>
         <p>Digital marketing this is online program. If you want to do your work from home on digital marketing related to your organization work than you can do easily work on digital marketing.</p>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="31">
        <Accordion.Header>Could you find your Answer here? </Accordion.Header>
        <Accordion.Body>
         <p>No? then click our online chat support button right of the webpage or feel free to call 7488444888 for better assistance.</p>
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
    </Col>
    </Row>
    </Container>
    </section>

      </div>
    )
  }
}

export default Faq
