import React, { Component } from 'react'
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom'

export class Contact extends Component {
  componentDidMount(){
    document.title = "Contact Us | Indofast"
}
  render() {
    return (
      <div>
                 <section className="hero-section gradient-overlay">
             <img src="img/banner/contact-us.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content ">
                        <h1 className="text-white mb-0">Contact Us</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Contact Us</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

    

    <section className="contact-us-section ptb-70">
        <Container className="contact">
           
            <Row className="justify-content-around">
                <Col lg="7">
                    <div className="contact-us-form gray-light-bg rounded p-4">
                        <h4>Ready to get started?</h4>

                        <Form >
        <Row>
        <Col lg="12">
      <Form.Group className="mb-3">
        <Form.Control type="text" placeholder="Enter Name" />
      </Form.Group>
      </Col>
      <Col lg="12">
      <Form.Group className="mb-3">
        <Form.Control type="email" placeholder="Enter Email" />
      </Form.Group>
      </Col>
      <Col lg="12">
      <Form.Group className="mb-3">
        <Form.Control type="number" placeholder="Enter Number" />
      </Form.Group>
      </Col>
 

    <Col lg="12">
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
       
        <Form.Control as="textarea" rows={5} placeholder="Enter Address" />
      </Form.Group>
    </Col>

    </Row>
    <div className='text-center'>
    <Button type="submit" className="btn secondary-solid-btn">
    Send Message
      </Button>
      </div>
    </Form>

                    </div>
                </Col>
                <Col lg="5">
                <div className="gray-light-bg rounded contact-page-section p-4">
                        <h4>Have Any Questions?</h4>
<p>Have a inquiry or some feedback for us? Fill out the form below to contact our team.</p>
                        <ul class="info-list">
							<li>
								<span class="icon-contact ti-location-pin icon-sm color-secondary"></span>
								<strong>Address</strong>
								Near Bihar Pharmacy College Gola Road, Bailey Rd, Patna, Bihar
							</li>
							<li>
								<span class="icon-contact ti-mobile icon-sm color-secondary mr-2"></span>
								<strong>Number</strong>
								<Link to="tel:+91 7488444888">+91 7488444888</Link>
							</li>
							<li>
								<span class="icon-contact fas fa-envelope mr-2"></span>
								<strong>Email</strong>
								<Link to="mailto:info@indofast.org">info@indofast.org</Link>
							</li>
						</ul>

                    </div>
                </Col>
            </Row>
        </Container>

    </section>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3597.787194814942!2d85.0561157744565!3d25.6119867147771!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39ed564e960ed1ef%3A0x245a71fe04dafa14!2sDIGITAL%20MARKETING%20TRAINING%20INSTITUTE%20IN%20PATNA%20%7C%20INDOFAST!5e0!3m2!1sen!2sin!4v1705308924987!5m2!1sen!2sin" width="100%" height="430" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    )
  }
}

export default Contact
