import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export class Content_Writing extends Component {
  render() {
    return (
      <div>
        <Helmet>
 <meta
      name="description"
      content="Web site created using create-react-app"/>
    <title>Content Writing | Indofast</title>
  
</Helmet>

<section className="hero-section gradient-overlay">
             <img src="img/banner/content_writing_banner.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">Content Writing</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Courses</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

    <section className="service-details-section ptb-100">
        <Container>
            <Row>
            
                <Col lg="8" md={8} >
                    <div className="service-details-wrap">
                        <img src="img/course-details/content_writing_three.webp" alt="services" className="img-fluid rounded shadow-sm"/>
                        <div className="services-detail-content mt-4">
                            <h4>Content Writing Training</h4>
                            <p>Do you want to make a profession in the Content Writing industry, but don’t know how to start? To clarify, Writing is a dream job and Content writing is one of the best parts of the writing profession. Do you ever ask why content is important in this digital world? Especially, you can’t promote or sell any things without making content. Really, if you want to make a sale and if you want to promote a business as a brand, content is the one and only way to prove anyone as an expert.</p>

                            <div className="row mt-5 course-detailis-page-ul">
                                <div className="col-md-6">
                                    <div className="img-wrap">
                                        <img src="img/course-details/content_writing.webp" alt="services" className="img-fluid"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <p>Are you looking for a content writing course in Patna Bihar? Generally, you will not find any institute where you can learn Content Writing. Basically, as a fresher, you can’t imagine a career in the Content Writing field, as we’re very weak in English. But this impossible work is handling one institute and this is indofast.org a leading digital marketing blog is providing Content Writing Course in Patna Bihar. With the increase of digital services, content marketing has become one of the best methods to generate sales and growing a brand. For this reason, content writing industry is feeling badly in need of expert Content Writer. Considering this, Indofast.org has taken a big decision to create new content writer with the needs of the industry.</p>
                              
                                </div>
                            </div>

                      
                            <div className="mt-4">
<h3>FAQs</h3>
</div>
                            <Accordion className="mt-2">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Introduction to Content Writing</Accordion.Header>
        <Accordion.Body>
        <p>As a newbie, learn various types of Content Writing Skills. In this module, you will learn what is Content Writing based on various businesses and what kind of content is required to create.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Email Writing</Accordion.Header>
        <Accordion.Body>
         <p>Learn how to draft great email campaigns such as subject lines, body content and call to action. In this module, you will learn various types of email drafting skills be it business mails, professional emails or internal communication.</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Creative Writing</Accordion.Header>
        <Accordion.Body>
         <p>Learn how to write creative content for your Books Fiction and Non-fiction, Poems, Scripts, Dialogue Placements & more. Understand the difference between creative writing audience over copywriting and content writing. </p>
        </Accordion.Body>
      </Accordion.Item>


    </Accordion>

                            <div className="action-btns mt-4">
                                <Link to="/enroll_now" className="btn secondary-solid-btn mr-3">Enroll Now</Link>
                            </div>

                        </div>
                    </div>
                </Col>

                <Col lg="4" md={4}>
                    <div className="sidebar-right p-4">

                     
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>All Course</h5>
                            </div>
                            <ul className="all-service-list">
                                <li><Link to="/web_design">Web Design Training</Link></li>
                                <li><Link to="/web_analysis">Web Analysis</Link></li>
                                <li><Link to="/content_writing">Content Writing Training</Link></li>
                                <li><Link to="/affiliate_marketing">Affiliate Marketing Training</Link></li>
                                <li><Link to="/pay_per_click">Pay Per Click (PPC) Training</Link></li>
                                <li><Link to="/sem">(SEM) Training</Link></li>
                                <li><Link to="/seo">Search Engine Optimization (SEO) Training</Link></li>
                                <li><Link to="/smm">Social Media Marketing (SMM) Training</Link></li>
                                <li><Link to="/online_reputation_management">Online Reputation Management Training</Link></li>
                                <li><Link to="/wordpress">WordPress Training</Link></li>
                                <li><Link to="/mobile_apps">Mobile Apps Training</Link></li>
                            </ul>
                        </aside>

                       
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>Need Help?</h5>
                            </div>
                            <p>We are available in 24/7 hours for dedicated support</p>
                            <ul className="primary-list mt-25">
                                <li><span className="ti-location-pin mr-2 color-primary"></span> Near Bihar Pharmacy College Gola Road, Bailey Rd, Patna, Bihar
                                </li>
                                <li><Link to="tel:+91 7488444888"><span className="ti-mobile mr-2 color-primary"></span> +91 7488444888</Link> </li>
                                <li> <Link to="mailto:info@indofast.org"><span className="ti-email mr-2 color-primary"></span> info@indofast.org</Link></li>
                            </ul>
                        </aside>

                      
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>Newsletter</h5>
                            </div>
                            <p>Enter your email address below to subscribe to my newsletter</p>
                            <form action="#" method="post"
                                  className="d-md-block d-lg-block">
                                <input type="text" className="form-control input" id="email-footer" name="email"
                                       placeholder="info@yourdomain.com"/>
                                <button type="submit" className="btn secondary-solid-btn btn-block btn-not-rounded mt-3">Subscribe
                                </button>
                            </form>
                        </aside>

                    </div>
                </Col>
          
            </Row>
        </Container>
    </section>

      </div>
    )
  }
}

export default Content_Writing
