import React, { Component } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export class Online_Reputation_Management extends Component {
  render() {
    return (
      <div>
                                <Helmet>
 <meta
      name="description"
      content="Web site created using create-react-app"/>
    <title>ORM | Indofast</title>
  
</Helmet>

<section className="hero-section gradient-overlay">
             <img src="img/banner/online-reputation-banner.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content">
                        <h1 className="text-white mb-0">ORM</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Courses</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>

    <section className="service-details-section pt-100 pb-50">
        <Container>
            <Row>
                <Col lg="8" md={8} >
                    <div className="service-details-wrap">
                        <img src="img/course-details/ormt-img.webp" alt="services" className="img-fluid rounded shadow-sm"/>
                        <div className="services-detail-content mt-4">
                            <h4>Online Reputation Management Training</h4>
                            <p>Reputation management refers to the influencing and controlling or concealing of an individual's or group's reputation. Originally a public relations term, the growth of the internet and social media, along with reputation management companies, have made search results a core part of an individual's or group's reputation. Online reputation management, sometimes abbreviated as ORM, focuses on the management of product and service search website results. Ethical grey areas include mug shot removal sites, astroturfing customer review sites, censoring negative complaints, and using search engine optimization tactics to influence results.</p>

                            <p>With extensive developments in this field of public relations, in-sync with the growth of the internet and social media, along with the advent of reputation management companies, the overall outlook of search results has become an integral part of what defines "reputation" and subsequent to all these developments, reputation management now exists under two spheres: online and offline reputation management.</p>

                        </div>
                    </div>
                </Col>

                <Col lg="4" md={4}>
                    <div className="sidebar-right p-4">

                     
                        <aside className="widget widget-categories">
                            <div className="widget-title">
                                <h5>All Course</h5>
                            </div>
                            <ul className="all-service-list">
                                <li><Link to="/web_design">Web Design Training</Link></li>
                                <li><Link to="/web_analysis">Web Analysis</Link></li>
                                <li><Link to="/content_writing">Content Writing Training</Link></li>
                                <li><Link to="/affiliate_marketing">Affiliate Marketing Training</Link></li>
                                <li><Link to="/pay_per_click">Pay Per Click (PPC) Training</Link></li>
                                <li><Link to="/sem">(SEM) Training</Link></li>
                                <li><Link to="/seo">Search Engine Optimization (SEO) Training</Link></li>
                                <li><Link to="/smm">Social Media Marketing (SMM) Training</Link></li>
                                <li><Link to="/online_reputation_management">Online Reputation Management Training</Link></li>
                                <li><Link to="/wordpress">WordPress Training</Link></li>
                                <li><Link to="/mobile_apps">Mobile Apps Training</Link></li>
                            </ul>
                        </aside>

                     

                    </div>
                </Col>

                <Col lg="12" md={12}>

<Row className=" mt-5 course-detailis-page-ul">
                <Col md={4} >
                    <div className="img-wrap">
                        <img src="img/course-details/orm-img-two.webp" alt="services" className="img-fluid"/>
                    </div>
                </Col>
                <Col md={8}>
                <p>Online reputation management focuses on the management of product and service search results within the digital space, that is why it is common to see the same suggested links in the first page of a Google search. A variety of electronic markets and online communities like e-Bay, Amazon and Alibaba have ORM systems built in, and using effective control nodes these can minimize the threat and protect systems from possible misuses and abuses by malicious nodes in decentralized overlay networks.</p>
              
                </Col>
            </Row>

            <div className="mt-5">
            <hr/>
            </div>

<Row className="justify-content-center">
    <Col lg="10">
    <div className="mt-3">
    <h3>ORM Course</h3>
    </div>
            <Accordion className="mt-2">
      <Accordion.Item eventKey="0">
        <Accordion.Header> Introduction to Online Reputation Management and Reputation Marketing</Accordion.Header>
        <Accordion.Body>
        <ul>
            <li>Difference between reputation marketing & management</li>
            <li>Discover assets that help in reputation management</li>
            <li>Learn about various brand sentiments</li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> Online Reputation Management: Negative to Neutral</Accordion.Header>
        <Accordion.Body>
        <ul>
            <li>Importance of online reviews & testimonials</li>
            <li>How to mitigate the impact of negative reviews</li>
            <li>Learn why monitoring brand mentions is essential</li>
        </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Crisis Management Protocols At A Brand Level</Accordion.Header>
        <Accordion.Body>
         <ul>
            <li>Learn how you can avoid an online crisis</li>
            <li>Discover steps of dealing with an online crisis</li>
            <li>Learn how crisis management protocols help</li>
         </ul>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>Additional Steps for Effective ORM</Accordion.Header>
        <Accordion.Body>
         <ul>
            <li>Use LinkedIn for reputation management</li>
            <li>Discover how indulging in brand wars helps</li>
         </ul>
        </Accordion.Body>
      </Accordion.Item>


    </Accordion>

                            <div className="action-btns mt-4">
                                <Link to="/enroll_now" className="btn secondary-solid-btn mr-3">Enroll Now</Link>
                            </div>
            </Col>
            </Row>
</Col>
              
            </Row>
        </Container>
    </section>

    <div className="mb-5">
            <hr/>
            </div>
    <section className="address-courses">
      <Container className="container custom-container">
        <Row className=" align-items-center justify-content-center">
          <Col lg="9" md={10} className="mb-30">
            <div className="section-title animation-style2 text-center">

             <h2>Contact Us</h2>
            </div>

          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="4" md={6}>
            <div className="contact-landing">
              <i className="fa fa-map-marker"></i>
         <h4>Reach Us</h4>
         <p>Near Bihar Pharmacy College Gola Road, Bailey Rd, Patna, Bihar 801503
          </p>
        </div>
          </Col>
          <Col lg="4" md={6}>
            <div className="contact-landing">
              <i className="fa fa-phone"></i>
            <h4>Call Us</h4>
            <p><span class="fas fa-phone-alt mr-2"></span> <a href="tel:+91 7488444888"> +91 7488444888</a></p>
         
          </div>
          </Col>
          <Col lg="4" md={6}>
            <div className="contact-landing">
              <i className="fa fa-envelope"></i>
            <h4>Mail Us</h4>
           <p> <a href="mailto:info@indofast.org">info@indofast.org</a></p>
      
          </div>
          </Col>
        
        </Row>
      </Container>
    </section>

      </div>
    )
  }
}

export default Online_Reputation_Management
