
import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from "react-router-dom";


export class Header extends Component {
  render() {
    return (
      <div>

{/* <header className="header home-page-header">
   <nav className="navbar navbar-expand-lg fixed-top">
       <Container>
           <Navbar.Brand className="navbar-brand" href="/">
               <img src="img/logo-color.png" alt="logo" className="img-fluid"/>
           </Navbar.Brand>
           <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                   aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
               <span className="ti-menu"></span>
           </button>
           <div className="collapse navbar-collapse h-auto" id="navbarSupportedContent">
               <ul className="navbar-nav ml-auto menu">
                   <li><Nav.Link href="/">Home</Nav.Link></li>
                   <li><Nav.Link href="#" className="dropdown-toggle">About Us</Nav.Link>
                       <ul className="sub-menu">
                           <li><Nav.Link href="/about" >About Us</Nav.Link></li>
                           <li><Nav.Link href="/director_message">Director Message</Nav.Link></li>
                       </ul>
                   </li>
                   <li><Nav.Link href="/courses">Our Courses</Nav.Link></li>
                   <li><Nav.Link href="#" className="dropdown-toggle">Gallery</Nav.Link>
                       <ul className="sub-menu">
                           <li><Nav.Link href="/gallery_categories" >Gallery</Nav.Link>
                           </li>
                           <li><Nav.Link href="/video">Video</Nav.Link></li>
                       </ul>
                   </li>
                   <li><Nav.Link href="/faq">Faq</Nav.Link></li>
                   <li><Nav.Link href="/blog">Blog</Nav.Link></li>
                   <li><Nav.Link href="/contact">Contact Us</Nav.Link></li>
                   <li className="bg-color-apply"><Nav.Link href="/enroll_now" className="btn check-btn btn-header">Enroll Now </Nav.Link></li>
               </ul>
           </div>
       </Container>
   </nav>
</header> */}

        {/* <header className="header home-page-header">
   
   <nav className="navbar navbar-expand-lg fixed-top">
       <Container>
           <NavLink className="navbar-brand" to="/">
               <img src="img/logo-color.png" alt="logo" className="img-fluid"/>
           </NavLink>
           <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                   aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
               <span className="ti-menu"></span>
           </button>
           <div className="collapse navbar-collapse h-auto" id="navbarSupportedContent">
               <ul className="navbar-nav ml-auto menu">
                   <li><NavLink to="/">Home</NavLink></li>
                   <li><NavLink to="#" className="dropdown-toggle">About Us</NavLink>
                       <ul className="sub-menu">
                           <li><NavLink to="/about" >About Us</NavLink></li>
                           <li><NavLink to="/director_message">Director Message</NavLink></li>
                       </ul>
                   </li>
                   <li><NavLink to="/courses">Our Courses</NavLink></li>
                   <li><NavLink to="#" className="dropdown-toggle">Gallery</NavLink>
                       <ul className="sub-menu">
                           <li><NavLink to="/gallery_categories" >Gallery</NavLink>
                           </li>
                           <li><NavLink to="/video">Video</NavLink></li>
                       </ul>
                   </li>
                   <li><NavLink to="/faq">Faq</NavLink></li>
                   <li><NavLink to="/blog">Blog</NavLink></li>
                   <li><NavLink to="/contact">Contact Us</NavLink></li>
                   <li className="bg-color-apply"><NavLink to="/enroll_now" className="btn check-btn btn-header">Enroll Now </NavLink></li>
               </ul>
           </div>
       </Container>
   </nav>
</header> */}
      </div>
    )
  }
}

export default Header