import React, { Component } from 'react'
import LightGallery from 'lightgallery/react';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom'

export class Video extends Component {
    componentDidMount(){
        document.title = "Video | Indofast"
    }
  render() {
    return (
      <div>
               <section className="hero-section gradient-overlay">
             <img src="img/banner/video.webp" alt=""/>
        <Container>
            
                    <div className="page-header-content ">
                        <h1 className="text-white mb-0">Video Gallery</h1>
                        <div className="custom-breadcrumb">
                            <ol className="breadcrumb bg-transparent py-0">
                                <li className="list-inline-item breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="list-inline-item breadcrumb-item active">Video</li>
                            </ol>
                        </div>
                    </div>
            
        </Container>
    </section>


    <Container>
    <LightGallery> 
{/* <video controls>
 <source src="img/movie.mp4" type="video/mp4"/>
</video>
       */}

<iframe width="100%" height="300" src="https://www.youtube.com/embed/3XD1oMKg-N0?si=khvJYYC1UHKvCLQC" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

<iframe width="100%" height="300" src="https://www.youtube.com/embed/v_OLSJFmdu4?si=FFop8J4vugW0sHqs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

<iframe width="100%" height="300" src="https://www.youtube.com/embed/uk-jYeypkPk?si=HtC4EnVDih647L_y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

<iframe width="100%" height="300" src="https://www.youtube.com/embed/nX2BPzD5nQY?si=UIdVvcDml3ov5YQ2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

<iframe width="100%" height="300" src="https://www.youtube.com/embed/D9WtzRG7XSE?si=z6fv85_t-AVM2HMP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

   </LightGallery>
    </Container>
      </div>
    )
  }
}

export default Video
